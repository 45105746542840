import { GlobalID } from "@/relay/RelayTypes"
import ProfilePopoverFields from "@/user/common/profile-popover/ProfilePopoverFields"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { Popover, PopoverProps } from "@material-ui/core"

interface Props {
  anchorEl: Element | null
  userId: GlobalID
  onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void
  open?: boolean
}

export const PROFILE_POPOVER_WIDTH = 320

function ProfilePopover(props: Props) {
  const { anchorEl, userId, onClose, open } = props
  const classes = useStyles({
    width: PROFILE_POPOVER_WIDTH,
  })

  return (
    <Popover
      anchorEl={anchorEl}
      open={typeof open === "boolean" ? open : Boolean(anchorEl)}
      onClose={onClose as PopoverProps["onClose"]}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        // Open the popover 8px to the right of the target element
        horizontal: PROFILE_POPOVER_WIDTH + 8,
      }}
      PaperProps={{ classes: { root: classes.popoverPaper } }}
    >
      <ProfilePopoverFields userId={userId} onClose={onClose} />
    </Popover>
  )
}

type StyleProps = {
  width: number
}

const useStyles = makeUseStyles((theme) => ({
  popoverPaper: ({ width }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(2.5),
    boxShadow: theme.palette.groovyDepths.boxShadow,
    margin: 0,
    width,
  }),
}))

export default ProfilePopover
