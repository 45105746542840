import { useAuthUser } from "@/core/context/AuthUserContext"
import { getLocalTimezone } from "@utils/timezone/timezoneUtils"

function useUserTimezone() {
  const { authUser } = useAuthUser()
  const systemTimezone = getLocalTimezone()

  // Use the timezone selected by the user in settings
  // Then use the system's timezone
  // Default to EST in case none of the above are available
  return authUser?.timezone || systemTimezone || "Canada/Eastern"
}

export default useUserTimezone
