import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { ProfileSettingsTab, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  MemberProfileContextQuery,
  MemberProfileContextQuery$data,
} from "@/user/settings/context/__generated__/MemberProfileContextQuery.graphql"
import { ProfileSettingsAccountTabSkeleton } from "@/user/settings/subtabs/account/ProfileSettingsAccountTab"
import { ProfileSettingsDetailsTabSkeleton } from "@/user/settings/subtabs/details/ProfileSettingsDetailsTab"
import { ProfileSettingsNotificationsTabSkeleton } from "@/user/settings/subtabs/notifications/ProfileSettingsNotificationsTab"
import { ProfileSettingsProfileTabSkeleton } from "@/user/settings/subtabs/profile/ProfileSettingsProfileTab"
import { ProfileSettingsRegistrationsTabSkeleton } from "@/user/settings/subtabs/registrations/ProfileSettingsRegistrationsTab"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoTextSkeleton } from "@disco-ui"
import { DiscoDrawerHeaderSkeleton } from "@disco-ui/drawer/DiscoDrawerHeader"
import { range } from "@utils/array/arrayUtils"
import { createContext, ReactNode, useContext } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  profileId: GlobalID
  children: ReactNode
}

type MemberProfileContextValue = {
  // To hint at typescript that this is a UserNode
  profile: MemberProfileContextQuery$data["user"] & { __typename: "User" }
  isSelfViewing: boolean
}

const MemberProfileContext = createContext<MemberProfileContextValue | null>(null)

export function useMemberProfileContext() {
  return useContext(MemberProfileContext)
}

/**
 * A context provider that is meant to be used within the member profile settings drawer.
 *
 * Since the drawer requires fetching the member's product membership within an experience and the organization membership within the current community.
 * *MemberProfileProvider* queries those memberships and provides the fragment keys for the child components as a global context
 * This removes the need for the child component fragment to define the `productMembershipId` and `organizationMembershipId` arguments
 * and use the fragment keys from `useMemberProfileContext` directly
 */
function MemberProfileProvider({ profileId, children }: Props) {
  const { authUser } = useAuthUser()
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const drawer = useGlobalDrawer("profileSettings")
  const isSelfViewing = drawer.params.drawerProfileId === authUser?.id
  const canViewNotes =
    !isSelfViewing &&
    Boolean(activeOrganization?.viewerPermissions.has("user_notes.read"))

  const { user } = useLazyLoadQuery<MemberProfileContextQuery>(
    graphql`
      query MemberProfileContextQuery(
        $id: ID!
        $productId: ID!
        $organizationId: ID!
        $isSelfViewing: Boolean!
        $canViewNotes: Boolean!
      ) {
        user: node(id: $id) {
          ... on User {
            id
            __typename
            ...ProfileSettingsProfileTabFragment
              @arguments(organizationId: $organizationId)
            ...ProfileSettingsAccountTabFragment
              @include(if: $isSelfViewing)
              @arguments(organizationId: $organizationId)
            organizationMembership(organizationId: $organizationId) {
              id
              role
              ...ProfileSettingsCertificateSectionFragment
              ...ProfileSettingsDetailsTabNotesFragment
                @arguments(canViewNotes: $canViewNotes)
              ...useMemberGroupTagsList_OrganizationMembershipFragment
              organization {
                viewerMembershipPlan {
                  id
                  name
                  isFreeMembership
                  ...CancelMembershipPlanButtonFragment
                }
              }
            }
            productMembership(productId: $productId) {
              id
              role
            }
            calendarConnection {
              id
            }
          }
        }
      }
    `,
    {
      id: profileId,
      productId: activeProduct?.id || "",
      organizationId: activeOrganization?.id || "",
      isSelfViewing,
      canViewNotes,
    },
    { fetchPolicy: "network-only" }
  )
  if (!Relay.isNodeType(user, "User")) return null

  return (
    <MemberProfileContext.Provider
      value={{
        profile: user,
        isSelfViewing,
      }}
    >
      {children}
    </MemberProfileContext.Provider>
  )
}

const useStyles = makeUseStyles((theme) => ({
  skeletonContainer: {
    padding: theme.spacing(4),
  },
  skeletonTabs: {
    display: "flex",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0, 4, 0),
  },
}))

const MemberProfileProviderSkeleton = () => {
  const classes = useStyles()
  const drawer = useGlobalDrawer("profileSettings")

  return (
    <div className={classes.skeletonContainer}>
      <DiscoDrawerHeaderSkeleton />

      {/* Tabs */}
      <div className={classes.skeletonTabs}>
        {range(5).map((i) => (
          <DiscoTextSkeleton key={i} width={75} />
        ))}
      </div>
      {getSkeletonForTab(drawer.params.profileSettingsTab)}
    </div>
  )
}

const getSkeletonForTab = (tab: ProfileSettingsTab) => {
  switch (tab) {
    case "profile":
      return <ProfileSettingsProfileTabSkeleton />
    case "details":
      return <ProfileSettingsDetailsTabSkeleton />
    case "account":
      return <ProfileSettingsAccountTabSkeleton />
    case "notifications":
      return <ProfileSettingsNotificationsTabSkeleton />
    case "registrations":
      return <ProfileSettingsRegistrationsTabSkeleton />
    default:
      return <ProfileSettingsProfileTabSkeleton />
  }
}

export default Relay.withSkeleton({
  component: MemberProfileProvider,
  skeleton: MemberProfileProviderSkeleton,
})
