/**
 * @generated SignedSource<<aabea9cb8b29dcdd18c624013df67183>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsCustomFormFieldsFragment$data = {
  readonly customFields: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
        readonly isPrivate: boolean;
        readonly webFormQuestionId: string | null;
      };
    }>;
  };
  readonly profileWebForm: {
    readonly currentRevision: {
      readonly questions: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly type: WebFormQuestionType;
            readonly richEditorBody: string;
            readonly options: ReadonlyArray<{
              readonly id: string;
              readonly label: string;
            }> | null;
          };
        }>;
      };
    };
  } | null;
  readonly " $fragmentType": "ProfileSettingsCustomFormFieldsFragment";
};
export type ProfileSettingsCustomFormFieldsFragment$key = {
  readonly " $data"?: ProfileSettingsCustomFormFieldsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsCustomFormFieldsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsCustomFormFieldsFragment",
  "selections": [
    {
      "alias": "customFields",
      "args": [
        {
          "kind": "Literal",
          "name": "isDefault",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": "visible"
        }
      ],
      "concreteType": "ProfileFieldNodeConnection",
      "kind": "LinkedField",
      "name": "profileFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileFieldNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProfileField",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isPrivate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "webFormQuestionId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "profileFields(isDefault:false,status:\"visible\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebForm",
      "kind": "LinkedField",
      "name": "profileWebForm",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WebFormRevision",
          "kind": "LinkedField",
          "name": "currentRevision",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormQuestionNodeConnection",
              "kind": "LinkedField",
              "name": "questions",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormQuestionNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormQuestion",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "richEditorBody",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WebFormQuestionOption",
                          "kind": "LinkedField",
                          "name": "options",
                          "plural": true,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "label",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "7736945cbc7224990255d4fb01f3cef4";

export default node;
