import UserNotesDeleteButton from "@/user/notes/list/item/delete-button/UserNotesDeleteButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { TestIDProps } from "@utils/typeUtils"

interface UserNotesItemMoreActionsDropdownProps extends TestIDProps {
  noteId: string
  moreActionsButtonClassName?: string
}

function UserNotesItemMoreActionsDropdown({
  noteId,
  moreActionsButtonClassName,
  testid,
}: UserNotesItemMoreActionsDropdownProps) {
  return (
    <DiscoMoreActionsDropdown
      testid={testid || ""}
      moreActionsButtonClassName={moreActionsButtonClassName}
    >
      <UserNotesDeleteButton noteId={noteId}>
        {(props) => (
          <div {...props}>
            <DiscoDropdownItem title={"Delete"} testid={`${testid}.delete`} />
          </div>
        )}
      </UserNotesDeleteButton>
    </DiscoMoreActionsDropdown>
  )
}

export default UserNotesItemMoreActionsDropdown
