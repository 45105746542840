import { useActiveSlackUserImportContext } from "@/core/context/ActiveSlackUserImportContext"
import { useFormStore } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import SlackUserAvatarWithEmail from "@/slack/slack-user-import-report/SlackUserAvatarWithEmail"
import SlackUserDropdownList from "@/slack/slack-user-import-report/SlackUserDropdownList"
import { SlackUserProfileDropdownFragment$key } from "@/slack/slack-user-import-report/__generated__/SlackUserProfileDropdownFragment.graphql"
import { SlackUserProfileDropdownMutation } from "@/slack/slack-user-import-report/__generated__/SlackUserProfileDropdownMutation.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayToast } from "@components/toast/ToastProvider"
import {
  DiscoDivider,
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
  DiscoInputSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { Popover } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { MouseEvent, useState } from "react"
import { graphql, useFragment } from "react-relay"

interface SlackUserProfileDropdownProps extends TestIDProps {
  memberKey: SlackUserProfileDropdownFragment$key
}

function SlackUserProfileDropdown(props: SlackUserProfileDropdownProps) {
  const { addMatch, removeMatch } = useActiveSlackUserImportContext()
  const { memberKey, testid = "SlackUserProfileDropdown" } = props
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [search, setSearch] = useState("")

  const importMember = useFragment<SlackUserProfileDropdownFragment$key>(
    graphql`
      fragment SlackUserProfileDropdownFragment on SlackUserImportMember {
        id
        mappedSlackUser {
          ...SlackUserAvatarWithEmailFragment
        }
        isFuzzyMatch
      }
    `,
    memberKey
  )

  const { id, mappedSlackUser, isFuzzyMatch } = importMember

  const form = useFormStore<SlackUserProfileDropdownMutation>(
    graphql`
      mutation SlackUserProfileDropdownMutation(
        $input: SetSlackUserIdOnImportMemberInput!
      ) {
        response: setSlackUserIdOnImportMember(input: $input) {
          node {
            id
            mappedSlackUser {
              ...SlackUserAvatarWithEmailFragment
            }
            isFuzzyMatch
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id,
    }
  )

  const classes = useStyles({
    isSuggested: isFuzzyMatch,
  })

  return (
    <>
      <DiscoTooltip
        content={
          isFuzzyMatch ? "Remove this selection if this pairing is incorrect." : ""
        }
      >
        <div>
          <DiscoContainerButton onClick={handleClick} className={classes.dropdownButton}>
            <SlackUserAvatarWithEmail
              userKey={mappedSlackUser}
              namePlaceholder={"Select a Slack user (optional)"}
            />
            <div className={classes.actionButtons}>
              {mappedSlackUser ? (
                <DiscoIconButton onClick={handleDeselectSlackUser}>
                  <DiscoIcon icon={"close"} />
                </DiscoIconButton>
              ) : (
                <DiscoIcon icon={"arrow-right"} />
              )}
            </div>
          </DiscoContainerButton>
        </div>
      </DiscoTooltip>
      {/** Refresh slack user list on open */}
      {Boolean(anchorEl) && (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          data-testid={`${testid}.Popover`}
          anchorOrigin={{
            vertical: -10,
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          PaperProps={{
            classes: { root: classes.popoverPaper },
            id: "slack-user-popover",
          }}
        >
          <div className={classes.listWrapper}>
            <DiscoInput
              data-testid={`search-input`}
              name={"title"}
              value={search}
              placeholder={"Search"}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              fullWidth
              startAdornment={<DiscoIcon icon={"search"} />}
            />
            <DiscoDivider />
            <SlackUserDropdownList
              search={search}
              importMemberId={id}
              onSelect={() => {
                // manually refetch the import so the pending count updates
                addMatch()
                handleClose()
              }}
            />
          </div>
        </Popover>
      )}
    </>
  )

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    if (mappedSlackUser) {
      displayToast({
        message: "Please deselect the user before selecting a new one",
      })
      return
    }

    setAnchorEl(e.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  async function handleDeselectSlackUser(e: MouseEvent<HTMLButtonElement>) {
    // Prevent it from opening the dropdown
    e.stopPropagation()

    const { didSave } = await form.submit({
      id: form.state.id,
      mappedUserId: null,
    })

    if (!didSave) return
    removeMatch()
    displayToast({
      message: "User deselected",
    })
  }
}

type StyleProps = {
  isSuggested: boolean
}

const useStyles = makeUseStyles((theme) => ({
  popoverPaper: {
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(2.5),
    boxShadow: theme.palette.groovyDepths.boxShadow,
    margin: 0,
    minWidth: 500,
  },
  dropdownButton: ({ isSuggested }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1.5),
    background: isSuggested
      ? "linear-gradient(69.77deg, rgba(221, 214, 254, 0.89) 12.15%, rgba(234, 241, 255, 0.89) 92.47%)"
      : theme.palette.background.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  actionButtons: {
    display: "flex",
    alignItems: "center",
  },
  listWrapper: {
    minHeight: 500,
  },
}))

export default Relay.withSkeleton({
  component: observer(SlackUserProfileDropdown),
  skeleton: () => <DiscoInputSkeleton height={72} />,
})
