import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import useMemberGroupTagsList from "@/product/common/member-group/common/tag/useMemberGroupTagsList"
import Relay from "@/relay/relayUtils"
import IconWithContent from "@/user/settings/common/icon-with-content/IconWithContent"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import { ProfileSettingsUserSectionFragment$key } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsUserSectionFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSection, DiscoText } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  userKey: ProfileSettingsUserSectionFragment$key
  isDetailsTab?: boolean
}

function ProfileSettingsUserSection(props: Props) {
  const { userKey, testid = "ProfileSettingsUserSection", isDetailsTab = false } = props
  const classes = useStyles()
  const { profile, isSelfViewing } = useMemberProfileContext()!
  const isMobile = useIsMobile()
  const permissions = useActiveOrganization()?.viewerPermissions
  const user = useFragment<ProfileSettingsUserSectionFragment$key>(
    graphql`
      fragment ProfileSettingsUserSectionFragment on User
      @argumentDefinitions(organizationId: { type: "ID!" }) {
        id
        bio
        websiteUrl
        timezone
        organizationMembership(organizationId: $organizationId) {
          id
          organization {
            defaultFields: profileFields(status: visible, isDefault: true) {
              edges {
                node {
                  id
                  title
                  isPrivate
                }
              }
            }
          }
        }
      }
    `,
    userKey
  )
  const { everyoneTags, adminOnlyTags } = useMemberGroupTagsList({
    organizationMembershipKey: profile.organizationMembership,
    linkToMembers: true,
  })
  const profileFields = Relay.connectionToArray(
    user.organizationMembership?.organization.defaultFields
  ).filter((pf) => pf.isPrivate === isDetailsTab)

  const showBio =
    profileFields.some((pf) => pf.title === "Short Bio") && (user.bio || isSelfViewing)
  const showGroups =
    profileFields.some((pf) => pf.title === "Public Groups") &&
    everyoneTags.length + adminOnlyTags.length > 0
  const showTimezone = profileFields.some((pf) => pf.title === "Local Timezone")
  if (!showBio && !showGroups && !showTimezone) return null

  return (
    <>
      <DiscoSection
        marginBottom={2}
        marginTop={isMobile || isDetailsTab ? 1.5 : 4}
        padding={0}
      >
        <div className={classes.subFieldsContainer}>
          {showBio && (
            <DiscoText
              marginBottom={1.5}
              fontStyle={user.bio ? undefined : "italic"}
              color={user.bio ? "text.secondary" : "text.disabled"}
              testid={`${testid}.bio`}
              className={classes.bio}
            >
              {user.bio || "Headline or a short description"}
            </DiscoText>
          )}
          {renderGroups()}
          {showTimezone && (
            <IconWithContent
              testid={`${testid}.IconWithContent.timezone`}
              icon={"time"}
              content={`${formatDateWithOptions({
                timeZone: user.timezone,
                shouldShiftDateToCompensateForTimezone: false,
                format: DATE_FORMAT.TIME_WITH_ZONE,
              })(new Date())} local time`}
            />
          )}
        </div>
      </DiscoSection>
    </>
  )

  function renderGroups() {
    if (!showGroups) return null
    if (!permissions?.has("member_groups.manage"))
      return (
        <IconWithContent
          icon={"group-share"}
          content={
            <div
              data-testid={`${testid}.group-tags`}
              className={classes.userTagsContainer}
            >
              {everyoneTags}
            </div>
          }
        />
      )
    return (
      <>
        {everyoneTags.length > 0 && (
          <IconWithContent
            icon={"group-share"}
            content={
              <div>
                <DiscoText variant={"body-sm-600"} marginBottom={0.5}>
                  {"Groups Visible to Everyone"}
                </DiscoText>
                <div
                  data-testid={`${testid}.everyone-tags`}
                  className={classes.userTagsContainer}
                >
                  {everyoneTags}
                </div>
              </div>
            }
          />
        )}
        {adminOnlyTags.length > 0 && (
          <IconWithContent
            icon={"group-share"}
            content={
              <div>
                <DiscoText variant={"body-sm-600"} marginBottom={0.5}>
                  {"Groups Visible to Admins Only"}
                </DiscoText>
                <div
                  data-testid={`${testid}.admin-only-tags`}
                  className={classes.userTagsContainer}
                >
                  {adminOnlyTags}
                </div>
              </div>
            }
          />
        )}
      </>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  bio: {
    whiteSpace: "pre-wrap",
  },
  userTagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
  subFieldsContainer: {
    display: "flex",
    flexDirection: "column",
  },
}))

export default ProfileSettingsUserSection
