import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { SlackUserDropdownListMutation } from "@/slack/slack-user-import-report/__generated__/SlackUserDropdownListMutation.graphql"
import { SlackUserDropdownListPaginationQuery } from "@/slack/slack-user-import-report/__generated__/SlackUserDropdownListPaginationQuery.graphql"
import { SlackUserDropdownListQuery } from "@/slack/slack-user-import-report/__generated__/SlackUserDropdownListQuery.graphql"
import { SlackUserDropdownList_PaginationFragment$key } from "@/slack/slack-user-import-report/__generated__/SlackUserDropdownList_PaginationFragment.graphql"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoGrid from "@disco-ui/layout/DiscoGrid"
import { DiscoTableSearchInputSkeleton } from "@disco-ui/table/header/search/DiscoTableSearchInput"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  search: string
  importMemberId: GlobalID
  onSelect: () => void
}

const SLACK_USERS_PER_PAGE = 5

function SlackUserDropdownList(props: Props) {
  const { search, importMemberId, onSelect } = props
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  const { organization } = useLazyLoadQuery<SlackUserDropdownListQuery>(
    graphql`
      query SlackUserDropdownListQuery(
        $id: ID!
        $search: String
        $first: Int!
        $after: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            slackUserImport {
              id
              ...SlackUserDropdownList_PaginationFragment
                @arguments(search: $search, first: $first, after: $after)
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      first: SLACK_USERS_PER_PAGE,
      search,
    },
    {
      fetchPolicy: "network-only",
    }
  )

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    SlackUserDropdownListPaginationQuery,
    SlackUserDropdownList_PaginationFragment$key
  >(
    graphql`
      fragment SlackUserDropdownList_PaginationFragment on SlackUserImport
      @refetchable(queryName: "SlackUserDropdownListPaginationQuery")
      @argumentDefinitions(
        search: { type: "String" }
        first: { type: "Int!" }
        after: { type: "String" }
      ) {
        members(assignable: true, search: $search, first: $first, after: $after)
          @connection(key: "SlackUserDropdownList__members") {
          __id
          totalCount
          edges {
            node {
              id
              slackUserId
              firstName
              lastName
              fullName
              slackUserIconUrl
              email
            }
          }
        }
      }
    `,
    organization?.slackUserImport || null
  )

  const form = useFormStore<SlackUserDropdownListMutation>(
    graphql`
      mutation SlackUserDropdownListMutation($input: SetSlackUserIdOnImportMemberInput!) {
        response: setSlackUserIdOnImportMember(input: $input) {
          node {
            id
            mappedSlackUser {
              ...SlackUserAvatarWithEmailFragment
            }
            isFuzzyMatch
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: importMemberId,
    }
  )

  if (!data) return null

  const slackUsers = Relay.connectionToArray(data.members)

  return (
    <>
      {slackUsers.length > 0 ? (
        <DiscoGrid
          customScrollParentSelector={"#slack-user-popover"}
          data={slackUsers}
          item={(index, ms) => {
            const slackUser = ms[index]
            return (
              <DiscoDropdownItem
                key={slackUser.id}
                icon={
                  <UserAvatar
                    user={{
                      first_name: slackUser.firstName,
                      last_name: slackUser.lastName,
                      avatar: slackUser.slackUserIconUrl,
                    }}
                    size={24}
                    shape={"square"}
                  />
                }
                onClick={() => handleSelectSlackUser(slackUser.id)}
                title={slackUser.fullName}
                subtitle={slackUser.email}
                component={"div"}
              />
            )
          }}
          listClassName={classes.list}
          refetch={{
            isLoading: isLoadingNext,
            hasNext,
            loadMore: () => loadNext(SLACK_USERS_PER_PAGE),
          }}
        />
      ) : (
        <DiscoText marginTop={2}>{"No slack users to select"}</DiscoText>
      )}
    </>
  )

  async function handleSelectSlackUser(mappedUserId: GlobalID) {
    const { didSave } = await form.submit({
      id: form.state.id,
      mappedUserId,
    })

    if (!didSave) return
    onSelect()
  }
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    alignItems: "center",
    justifyContent: "stretch",
    marginTop: theme.spacing(2),
  },
}))

export default Relay.withSkeleton({
  component: SlackUserDropdownList,
  skeleton: () => <DiscoTableSearchInputSkeleton />,
})
