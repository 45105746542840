import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import { isWebViewContentPage } from "@/product/util/hook/useIsWebView"
import ProfileNameWithTag from "@/user/common/name-with-tag/ProfileNameWithTag"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import ProfileSettingsCertificateSection from "@/user/settings/subtabs/profile/ProfileSettingsCertificateSection"
import ProfileSettingsCustomFields, {
  ProfileSettingsCustomFieldsSkeleton,
} from "@/user/settings/subtabs/profile/ProfileSettingsCustomFields"
import ProfileSettingsCustomFormFields from "@/user/settings/subtabs/profile/ProfileSettingsCustomFormFields"
import ProfileSettingsProfileBanner, {
  ProfileSettingsProfileBannerSkeleton,
} from "@/user/settings/subtabs/profile/ProfileSettingsProfileBanner"
import ProfileSettingsProfileFormFields from "@/user/settings/subtabs/profile/ProfileSettingsProfileFormFields"
import ProfileSettingsUserSection from "@/user/settings/subtabs/profile/ProfileSettingsUserSection"
import {
  UpdateUserProfileFormState,
  initUpdateProfileWebFormSubmissionInput,
} from "@/user/settings/subtabs/profile/ProfileSettingsUtils"
import { ProfileSettingsProfileTabFragment$key } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsProfileTabFragment.graphql"
import { ProfileSettingsProfileTabMutation } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsProfileTabMutation.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import Form from "@components/form/Form"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoTextSkeleton } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  userKey: ProfileSettingsProfileTabFragment$key
}

function ProfileSettingsProfileTab(props: Props) {
  const { userKey, testid = "ProfileSettingsProfileTab" } = props
  const drawer = useGlobalDrawer("profileSettings")
  const isEditing = drawer.params.editProfile === "1"
  const classes = useStyles({ isEditing })
  const { updateAuthUserInStore } = useAuthUser()
  const { isSelfViewing } = useMemberProfileContext()!
  const isMobile = useIsMobile()
  const activeOrganization = useActiveOrganization()

  const user = useFragment<ProfileSettingsProfileTabFragment$key>(
    graphql`
      fragment ProfileSettingsProfileTabFragment on User
      @argumentDefinitions(organizationId: { type: "ID!" }) {
        id
        website
        timezone
        bio
        lastName
        firstName
        avatar
        cover
        organizationMembership(organizationId: $organizationId) {
          profileValues {
            edges {
              node {
                id
                profileField {
                  webFormQuestionId
                }
                value
                # Need to select this or else it can end up undefined on member mgmt page
                selectedOptions
                webFormAnswer {
                  body
                  selectedOptions
                }
              }
            }
          }
          organization {
            profileWebForm {
              currentRevision {
                ...webFormFillerUtils_getFormStateFromRevisionFragment @relay(mask: false)
              }
            }
            ...ProfileSettingsProfileFormFieldsFragment
            ...ProfileSettingsCustomFormFieldsFragment
          }
          ...ProfileSettingsCustomFieldsFragment @arguments(isPrivate: false)
        }
        ...ProfileSettingsProfileBannerFragment
        ...ProfileSettingsUserSectionFragment @arguments(organizationId: $organizationId)
        ...ProfileNameWithTagFragment
      }
    `,
    userKey
  )
  const membership = user.organizationMembership

  // Set up web form submission input using existing values
  const webFormSubmission = initUpdateProfileWebFormSubmissionInput(
    membership?.organization.profileWebForm?.currentRevision,
    membership?.profileValues
  )

  const form = useFormStore<
    ProfileSettingsProfileTabMutation,
    UpdateUserProfileFormState
  >(
    graphql`
      mutation ProfileSettingsProfileTabMutation(
        $input: UpdateUserProfileInput!
        $organizationId: ID!
      ) {
        response: updateProfile(input: $input) {
          node {
            avatar
            ...ProfileSettingsProfileTabFragment
              @arguments(organizationId: $organizationId)
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      avatar: user.avatar,
      bio: user.bio,
      cover: user.cover,
      timezone: user.timezone,
      webFormSubmission,
    }
  )

  const { setUnsavedChanges } = useUnsavedChangesModalContext()
  useEffect(() => {
    setUnsavedChanges(form.isChanged)
  }, [form.isChanged, setUnsavedChanges])

  useEffect(() => {
    if (drawer.params.editProfile === "0" && form.isChanged) form.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawer.params.editProfile, form.isChanged])

  return (
    <>
      <ScrollShadowContainer
        classes={{
          parentContainer: classes.parentContainer,
          scrollContainer: classes.scrollContainer,
        }}
      >
        <Form
          id={`${testid}.Form`}
          testid={`${testid}.Form`}
          onSubmit={handleSubmit}
          errorInfo={null}
        >
          {/** Cover photo, avatar and name / cover and avatar form fields */}
          <ProfileSettingsProfileBanner form={form} userKey={user} />
          {isMobile && <ProfileNameWithTag userKey={user} />}
          {isEditing && isSelfViewing && membership?.organization ? (
            <>
              <ProfileSettingsProfileFormFields
                form={form}
                organizationKey={membership.organization}
              />
              <ProfileSettingsCustomFormFields
                form={form}
                organizationKey={membership.organization}
              />
            </>
          ) : (
            <>
              <ProfileSettingsUserSection userKey={user} />
              {membership && (
                <ProfileSettingsCustomFields organizationMembershipKey={membership} />
              )}
              <ProfileSettingsCertificateSection />
            </>
          )}
        </Form>
      </ScrollShadowContainer>

      {isEditing && (
        <div className={classes.buttonsContainer}>
          <DiscoButton
            color={"grey"}
            variant={"outlined"}
            disabled={form.isSubmitting}
            onClick={() => drawer.setParams({ editProfile: "0" })}
            testid={`${testid}.cancel-button`}
          >
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton
            testid={`${testid}.submit-button`}
            id={`${testid}.Form`}
            form={form}
          >
            {"Save Changes"}
          </Form.SubmitButton>
        </div>
      )}
    </>
  )

  async function handleSubmit() {
    const { didSave, response } = await form.submit(
      {
        ...form.state,
        // Only submit the web form if it actually changed to avoid creating
        // unnecessary submissions
        webFormSubmission: form.changedState.webFormSubmission
          ? form.state.webFormSubmission
          : null,
      },
      {
        variables: { organizationId: activeOrganization?.id || "" },
      }
    )

    if (!didSave || !response?.node) return

    displaySuccessToast({
      testid: `${testid}.Form.success-toast`,
      message: "Profile updated!",
    })

    updateAuthUserInStore({
      avatar: response.node.avatar,
    })
    drawer.setParams({ editProfile: "0" })
  }
}

export const ProfileSettingsProfileTabSkeleton = () => {
  return (
    <>
      <ProfileSettingsProfileBannerSkeleton />
      <DiscoTextSkeleton marginTop={3} width={"100%"} />
      <DiscoTextSkeleton marginBottom={2} width={"40%"} />
      <ProfileSettingsCustomFieldsSkeleton />
    </>
  )
}

type StyleProps = {
  isEditing: boolean
}

const useStyles = makeUseStyles((theme) => ({
  parentContainer: ({ isEditing }: StyleProps) => ({
    ...styleIf(isEditing, {
      paddingBottom: "80px",
    }),
  }),
  scrollContainer: {
    padding: theme.spacing(2.5, 3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2.5, 2),
    },
  },
  buttonsContainer: {
    borderTop: theme.palette.constants.borderSmall,
    height: "80px",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(1),
    position: isWebViewContentPage() ? "fixed" : "absolute",
    bottom: 0,
    zIndex: 2,
    padding: theme.spacing(0, 3),
  },
}))

export default observer(ProfileSettingsProfileTab)
