import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import UserAvatar, { UserAvatarShape } from "@/user/common/avatar/UserAvatar"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { getUserFullName } from "../../util/userUtils"

interface ProfileListModalListItemProps {
  user: UserAvatarShape
  testid?: string
  onClose: () => void
}

function ProfileListModalListItem({
  user,
  testid,
  onClose,
}: ProfileListModalListItemProps) {
  const classes = useStyles()
  const profileDrawer = useGlobalDrawer("profileSettings")

  const content = (
    <>
      <UserAvatar
        testid={`${testid}.MultipleAvatars.${user.first_name}`}
        user={user}
        size={32}
      />
      <DiscoText variant={"body-sm"} testid={`${testid}.name`}>
        {user?.name ?? getUserFullName(user)}
      </DiscoText>
    </>
  )

  return user.id ? (
    <DiscoContainerButton
      className={classes.container}
      testid={`${testid}.root`}
      onClick={openProfile}
    >
      {content}
    </DiscoContainerButton>
  ) : (
    <div className={classes.container} data-testid={`${testid}.root`}>
      {content}
    </div>
  )

  function openProfile() {
    profileDrawer.open({
      drawerProfileId: user.id,
      profileSettingsTab: "profile",
    })
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}))

export default ProfileListModalListItem
