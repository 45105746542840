/**
 * @generated SignedSource<<07d3cb3d8a8e7357497b84dcf117c499>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationChannel = "email" | "slack" | "in_app" | "mobile" | "%future added value";
export type NotificationKindSlug = "assignment_submitted" | "new_registration" | "curriculum_module_released" | "comment_reply_chat_mention" | "upcoming_course_event" | "new_direct_message" | "event_share" | "occurrence_capacity_reached" | "post_share" | "post_published" | "new_community_member" | "content_flagged" | "event_published" | "curriculum_due_dates" | "curriculum_item_share" | "quiz_submitted" | "survey_submitted" | "new_bot_suggestion" | "new_guest_registration" | "%future added value";
export type UpdateNotificationOptOutsInput = {
  notificationKindSlug: NotificationKindSlug;
  channels: ReadonlyArray<NotificationChannel>;
  organizationId: string;
  productId?: string | null;
  mergeWithExisting?: boolean | null;
};
export type NotificationKindListItemMutation$variables = {
  input: UpdateNotificationOptOutsInput;
};
export type NotificationKindListItemMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type NotificationKindListItemMutation = {
  variables: NotificationKindListItemMutation$variables;
  response: NotificationKindListItemMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateNotificationOptOutsResponse",
    "kind": "LinkedField",
    "name": "updateNotificationOptOuts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationOptOut",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationKindListItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationKindListItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "40169523f02f47bd63dd3ef6d8dcf8c4",
    "id": null,
    "metadata": {},
    "name": "NotificationKindListItemMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationKindListItemMutation(\n  $input: UpdateNotificationOptOutsInput!\n) {\n  response: updateNotificationOptOuts(input: $input) {\n    node {\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c86ed40272ecc1708ff05791e11b2e43";

export default node;
