import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import { ProfileSettingsNotificationsTabQuery } from "@/user/settings/subtabs/notifications/__generated__/ProfileSettingsNotificationsTabQuery.graphql"
import NotificationFamilyListItem, {
  NotificationFamilyListItemSkeleton,
} from "@/user/settings/subtabs/notifications/family/NotificationFamilyListItem"
import { DiscoDivider, DiscoText } from "@disco-ui"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

const ProfileSettingsNotificationsTab = () => {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  // Fetch notification families and kinds
  const { node } = useLazyLoadQuery<ProfileSettingsNotificationsTabQuery>(
    graphql`
      query ProfileSettingsNotificationsTabQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            notificationFamilies {
              edges {
                node {
                  id
                  notificationKinds {
                    totalCount
                  }
                  ...NotificationFamilyListItemFragment @arguments(organizationId: $id)
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    },
    {
      fetchPolicy: "network-only",
    }
  )

  const families = Relay.connectionToArray(node?.notificationFamilies)

  // If there are no notification families, or no kinds in the returned families
  const noNotifications =
    !families.length || families.every((f) => !f.notificationKinds.totalCount)

  // If there are no notifications to manage
  if (noNotifications || !node)
    return (
      <div className={classes.empty}>
        <DiscoText variant={"body-sm"}>
          {"There are no notifications to manage."}
        </DiscoText>
      </div>
    )

  return (
    <div data-testid={"ProfileSettingsNotificationsTab"}>
      {/* Notification families */}
      {families.slice(0, Math.max(families.length - 1, 0)).map((family) => (
        <NotificationFamilyListItem key={family.id} familyKey={family} />
      ))}
      {/* Last item */}
      <NotificationFamilyListItem familyKey={families.slice(-1)[0]} showDivider={false} />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  empty: {
    padding: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "center",
  },
}))

export const ProfileSettingsNotificationsTabSkeleton = () => {
  return (
    <div>
      <NotificationFamilyListItemSkeleton />
      <DiscoDivider marginTop={1} marginBottom={1} />
      <NotificationFamilyListItemSkeleton />
    </div>
  )
}

export default Relay.withSkeleton({
  component: ProfileSettingsNotificationsTab,
  skeleton: ProfileSettingsNotificationsTabSkeleton,
})
