import { DateFormatKey, FormattedDateMap } from "@disco-ui/date/DiscoDatePicker"
import { DATE_FORMAT, YYYY_MM_DD_DATE_FORMAT_REGEX } from "@utils/time/timeConstants"
import { compensateForTimeZone, formatDateWithOptions } from "@utils/time/timeUtils"
import { addDays, format, isAfter, isBefore, isValid } from "date-fns"

namespace DateUtils {
  export function isDateStringValid(value: string | null | undefined) {
    return value ? YYYY_MM_DD_DATE_FORMAT_REGEX.test(value) : false
  }

  export function getDateStringFromDateObject(dateObject: Date | null) {
    return dateObject instanceof Date
      ? formatDateWithOptions({
          format: DATE_FORMAT.API_FORMAT,
          // DatePicker returns date in a format that doesn't need compensation
          shouldShiftDateToCompensateForTimezone: false,
        })(dateObject)
      : ""
  }

  export function getDateObjectFromDateString(dateString: string | null | undefined) {
    return isDateStringValid(dateString)
      ? compensateForTimeZone(new Date(dateString!))
      : null
  }

  /**
   * Returns the formatted date strings for a given date
   * If the date is not provided, the map will have empty string as values
   *
   * @example
   *  {
   *    "yyyy-MM-dd": "2012-01-28"
   *  }
   */
  export function getFormattedDates(date: Date | undefined | null) {
    const formats: DateFormatKey[] = [DATE_FORMAT.API_FORMAT]

    return formats.reduce((acc, curr) => {
      acc[curr] = date ? format(date, curr) : ""
      return acc
    }, {} as FormattedDateMap)
  }

  export function formatDateInput(date: Date | undefined | null) {
    return date ? format(date, DATE_FORMAT.DEFAULT_FULL_MONTH) : ""
  }

  export function parseDate(value: string, minDate?: Date, maxDate?: Date) {
    // Adjust from UTC to local timezone when user types in yyyy-MM-dd format
    const date = getDateObjectFromDateString(value) || new Date(value)
    return !isValid(date) ||
      (minDate && isBefore(date, minDate)) ||
      (maxDate && isAfter(date, maxDate))
      ? null
      : date
  }

  export function getOneWeekFromToday() {
    return addDays(new Date(), 7).toISOString()
  }

  /** Format seconds to 1h20m2s or 20m2s or 2s */
  export function formatSecondsToHMS(seconds: number, options?: { spaceOut?: boolean }) {
    const { spaceOut } = options || {}

    if (seconds < 0) return "0s" // Handle negative values gracefully

    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60

    let result = ""
    if (hours > 0) {
      result += `${hours}h`
    }
    if (minutes > 0 || hours > 0) {
      // Display minutes if hours are present or minutes are non-zero
      result += `${minutes}m`
    }

    result += `${Math.round(secs)}s`

    // Space out transforms 1h20m2s into 1h 20m 2s
    return spaceOut ? result.replace(/([hm])/g, "$1 ") : result
  }
}

export default DateUtils
