/**
 * @generated SignedSource<<dc6877262a08499395271bccf941f53d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsProductRegistrationTable_PaginationFragment$data = {
  readonly id: string;
  readonly experienceProductMemberships: {
    readonly __id: string;
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly id: string;
        readonly role: ProductRole;
        readonly product: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsProductRegistrationTableRowFragment">;
        };
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly startCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
  } | null;
  readonly " $fragmentType": "ProfileSettingsProductRegistrationTable_PaginationFragment";
};
export type ProfileSettingsProductRegistrationTable_PaginationFragment$key = {
  readonly " $data"?: ProfileSettingsProductRegistrationTable_PaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsProductRegistrationTable_PaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "experienceProductMemberships"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./ProfileSettingsProductRegistrationTablePaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "ProfileSettingsProductRegistrationTable_PaginationFragment",
  "selections": [
    (v1/*: any*/),
    {
      "alias": "experienceProductMemberships",
      "args": [
        {
          "kind": "Literal",
          "name": "productType",
          "value": "course"
        }
      ],
      "concreteType": "ProductMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "__ProfileSettingsProductRegistrationTable_experienceProductMemberships_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductMembershipNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductMembership",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProfileSettingsProductRegistrationTableRowFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": "__ProfileSettingsProductRegistrationTable_experienceProductMemberships_connection(productType:\"course\")"
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
})();

(node as any).hash = "c2b62fd42837af98a0b19856a2d14139";

export default node;
