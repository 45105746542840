import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoLink, DiscoText } from "@disco-ui"

interface Props {
  testid?: string
}

function PromptToSignInLink({ testid }: Props): JSX.Element {
  return (
    <DiscoText variant={"body-sm"} color={"text.secondary"} align={"center"}>
      {"Already have an account? "}
      <DiscoLink
        to={{
          pathname: ROUTE_NAMES.AUTHENTICATION.LOGIN,
          state: { redirectUrl: location.href },
        }}
      >
        <DiscoText
          testid={testid || "PromptToSignInLink.link"}
          variant={"body-sm"}
          color={"primary.main"}
          display={"inline"}
        >
          {"Sign in"}
        </DiscoText>
      </DiscoLink>
    </DiscoText>
  )
}

export default PromptToSignInLink
