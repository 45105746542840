import { ProductRole } from "@/admin/members/roles/__generated__/RoleAvatarStackQuery.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import ChevronIcon from "@/core/ui/iconsax/linear/arrow-up-1.svg"
import ExternalIcon from "@/core/ui/iconsax/linear/export-2.svg"
import ProductWithDetails from "@/product/common/ProductWithDetails"
import ProductTypeTag from "@/product/common/type-tag/ProductTypeTag"
import ProductUtils from "@/product/util/productUtils"
import ProductRoleTag from "@/role/ProductRoleTag"
import { ProfileSettingsProductRegistrationTableRowFragment$key } from "@/user/settings/subtabs/registrations/table/__generated__/ProfileSettingsProductRegistrationTableRowFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIconButton, DiscoTable, DiscoText, DiscoTextButton } from "@disco-ui"
import { Collapse, TableCell } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  productKey: ProfileSettingsProductRegistrationTableRowFragment$key
  productRole: ProductRole
}

function ProfileSettingsProductRegistrationTableRow(props: Props) {
  const {
    productKey,
    productRole,
    testid = "ProfileSettingsProductRegistrationTableRow",
  } = props

  const history = useHistory()
  const isMobile = useIsMobile()
  const [isExpanded, setIsExpanded] = useState(false)
  const experienceLabel = useLabel("experience")
  const classes = useStyles({
    isExpanded,
  })

  const product = useFragment<ProfileSettingsProductRegistrationTableRowFragment$key>(
    graphql`
      fragment ProfileSettingsProductRegistrationTableRowFragment on Product {
        name
        type
        slug
        startsAt
        endsAt
        ...ProductTypeTagFragment
        ...ProductWithDetailsFragment
      }
    `,
    productKey
  )

  return (
    <DiscoTable.Row
      onClick={isMobile ? undefined : goToExperienceDashboard}
      testid={`${testid}.experience.${product.name}`}
    >
      {isMobile ? (
        <TableCell width={"100%"} onClick={toggleCollapse}>
          <div className={classes.header}>
            <ProductWithDetails productKey={product} hideDetails />
            <DiscoIconButton>
              <ChevronIcon className={classes.chevronIcon} />
            </DiscoIconButton>
          </div>
          <Collapse in={isExpanded} className={classes.collapseContainer}>
            <DiscoText
              variant={"body-xs-500"}
              display={"block"}
              marginTop={1.5}
              color={"groovy.grey.300"}
            >
              {"Duration"}
            </DiscoText>
            <DiscoText variant={"body-sm"} marginTop={0.5} color={"text.secondary"}>
              {`${ProductUtils.getStartDateText(product.type, product.startsAt)} · 
          ${ProductUtils.displayDuration(product)}`}
            </DiscoText>
            <DiscoText
              variant={"body-xs-500"}
              display={"block"}
              marginTop={1.5}
              color={"groovy.grey.300"}
            >
              {"Label"}
            </DiscoText>
            <ProductTypeTag
              tagProps={{
                marginTop: 0.5,
              }}
              testid={`${testid}.ProductTypeTag`}
              productKey={product}
            />

            <DiscoTextButton
              leftIcon={<ExternalIcon />}
              onClick={goToExperienceDashboard}
              className={classes.mobileLinkButton}
              textVariant={"body-sm-600"}
            >
              {`View ${experienceLabel.singular}`}
            </DiscoTextButton>
          </Collapse>
        </TableCell>
      ) : (
        <>
          <TableCell width={"50%"}>
            <ProductWithDetails productKey={product} />
          </TableCell>
          <TableCell width={"25%"}>
            <ProductTypeTag productKey={product} testid={`${testid}.ProductTypeTag`} />
          </TableCell>
          <TableCell width={"25%"}>
            <ProductRoleTag productRole={productRole} />
          </TableCell>
        </>
      )}
    </DiscoTable.Row>
  )

  function toggleCollapse() {
    setIsExpanded((v) => !v)
  }

  function goToExperienceDashboard() {
    history.push(
      generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
        productSlug: product.slug,
      })
    )
  }
}

type StyleProps = {
  isExpanded: boolean
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mobileLinkButton: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
  chevronIcon: ({ isExpanded }: StyleProps) => ({
    transition: "transform 300ms ease-in-out",
    transform: `rotate(${isExpanded ? 180 : 90}deg)`,
  }),
  collapseContainer: {
    marginLeft: theme.spacing(6.5),
  },
}))

export default ProfileSettingsProductRegistrationTableRow
