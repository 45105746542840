import CommentUtils from "@/comments/utils/CommentUtils"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { SearchHitType } from "@/search/search-hits/SearchHit"
import SearchHitTemplate, {
  SearchHitTemplateSkeleton,
} from "@/search/search-hits/SearchHitTemplate"
import { UserSearchHitQuery } from "@/search/search-hits/kinds/__generated__/UserSearchHitQuery.graphql"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

export type UserHit = {
  firstName: string
  lastName: string
  bio: string
  email?: string
}

interface UserSearchHitProps extends TestIDProps {
  hit: SearchHitType
}

function UserSearchHit({ hit }: UserSearchHitProps) {
  const profileDrawer = useGlobalDrawer("profileSettings")
  const copyToClipboard = useCopyToClipboard()

  const { node } = useLazyLoadQuery<UserSearchHitQuery>(
    graphql`
      query UserSearchHitQuery($id: ID!) {
        node(id: $id) {
          ... on User {
            id
            fullName
            ...ProfileAvatarWithDetailsFragment
            ...ProfileAvatarFragment
          }
        }
      }
    `,
    {
      id: hit.id as GlobalID,
    }
  )

  const user = {
    id: node?.id || (hit.id as GlobalID),
    firstName: hit.user?.firstName,
    lastName: hit.user?.lastName,
    bio: hit.user?.bio,
    email: hit.user?.email,
    fullName: node?.fullName || CommentUtils.getUnknownUserFullName(),
  }

  if (!user) return null
  if (!node) return null

  return (
    <SearchHitTemplate
      hit={hit}
      icon={<ProfileAvatar userKey={node} size={40} />}
      name={user.fullName}
      details={user.email}
      onClick={handleClick}
      onCopy={handleCopyLink}
    />
  )

  function handleClick() {
    profileDrawer.open({
      drawerProfileId: user.id,
      profileSettingsTab: "profile",
    })
  }

  function handleCopyLink(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()

    const profileDrawerLink = `${window.location.href}?${setSearchParams<
      GlobalDrawerParams<"profileSettings">
    >("", {
      drawerProfileId: user.id,
      profileSettingsTab: "profile",
    })}`
    copyToClipboard(profileDrawerLink)
  }
}

export const UserSearchHitSkeleton: React.FC<UserSearchHitProps> = () => {
  return <SearchHitTemplateSkeleton />
}

export default Relay.withSkeleton<UserSearchHitProps>({
  component: UserSearchHit,
  skeleton: UserSearchHitSkeleton,
})
