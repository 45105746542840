/**
 * @generated SignedSource<<df996da1fcbd679fef35e642f9bee643>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileAvatarWithDetailsFragment$data = {
  readonly id: string;
  readonly fullName: string;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  readonly " $fragmentType": "ProfileAvatarWithDetailsFragment";
};
export type ProfileAvatarWithDetailsFragment$key = {
  readonly " $data"?: ProfileAvatarWithDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileAvatarWithDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "21d49187b71096f46330e7eb71a86963";

export default node;
