import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { SearchHitType } from "@/search/search-hits/SearchHit"
import SearchHitTemplate, {
  SearchHitTemplateSkeleton,
} from "@/search/search-hits/SearchHitTemplate"
import { OccurrenceSearchHitQuery } from "@/search/search-hits/kinds/__generated__/OccurrenceSearchHitQuery.graphql"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DaySquare from "@components/date/day-square/DaySquare"
import { DiscoText } from "@disco-ui"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type OccurrenceHit = {
  id: string
  event: {
    summary: string
  }
  content: {
    name: string
    description: string | null
    richEditorContentDescription: string | null
  }
}

interface OccurrenceSearchHitProps extends TestIDProps {
  hit: SearchHitType
}

function OccurrenceSearchHit({ hit }: OccurrenceSearchHitProps) {
  const classes = useStyles()
  const copyToClipboard = useCopyToClipboard()
  const history = useHistory()
  const timeZone = useUserTimezone()

  const { occurrence } = useLazyLoadQuery<OccurrenceSearchHitQuery>(
    graphql`
      query OccurrenceSearchHitQuery($id: ID!) {
        occurrence: node(id: $id) {
          ... on Occurrence {
            id
            name
            datetimeRange
            product {
              type
              name
            }
          }
        }
      }
    `,
    {
      id: hit.id as GlobalID,
    }
  )

  if (!occurrence) return null

  return (
    <SearchHitTemplate
      hit={hit}
      icon={
        occurrence.datetimeRange && (
          <div className={classes.daySquareContainer}>
            <DaySquare
              testid={`OccurrenceSearchHit.DaySquare`}
              date={new Date(occurrence.datetimeRange[0])}
              formatterOptions={{ timeZone }}
              customClassName={classes.daySquare}
            />
          </div>
        )
      }
      name={occurrence.name}
      details={renderLabel()}
      onClick={handleClick}
      onCopy={handleCopyLink}
    />
  )

  function renderLabel() {
    const date = occurrence?.datetimeRange
      ? formatDateWithOptions({
          timeZone,
          format: DATE_FORMAT.DATE_WITH_SHORT_TIME_FORMAT_AT,
        })(new Date(occurrence?.datetimeRange[0]))
      : ""

    const product = occurrence?.product

    return (
      <DiscoText
        variant={"body-sm"}
        color={"groovy.neutral.400"}
        truncateText={1}
        className={classes.subtitle}
        display={"inline"}
      >
        {date}
        {product?.type === "course" && (
          <>
            {" • "}
            {`Hosted in `}
            <DiscoText
              component={"span"}
              variant={"body-sm"}
              color={"primary.main"}
              truncateText={1}
              className={classes.product}
            >
              {product.name}
            </DiscoText>
          </>
        )}
      </DiscoText>
    )
  }

  function handleClick() {
    if (!occurrence) return

    history.push({
      pathname: generatePath(ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT),
      search: setSearchParams<GlobalDrawerParams<"event">>(location.search, {
        drawerOccurrenceId: occurrence.id!,
        drawerEventTab: "details",
      }),
    })
  }

  function handleCopyLink(e: React.MouseEvent<HTMLButtonElement>) {
    if (!occurrence) return
    e.stopPropagation()

    const occurrenceLink = `${window.location.origin}${generatePath(
      ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT
    )}?drawerOccurrenceId=${occurrence.id}&drawerEventTab=details`

    copyToClipboard(occurrenceLink)
  }
}

const useStyles = makeUseStyles((theme) => ({
  daySquareContainer: {
    border: `2px solid ${theme.palette.background.paper[20]}`,
    boxSizing: "content-box",
    borderRadius: theme.measure.borderRadius.medium,
  },
  daySquare: {
    flexShrink: 0,
    width: "40px",
    height: "40px",
  },
  subtitle: {
    width: "100%",
  },
  product: {
    display: "contents",
  },
}))

export const OccurrenceSearchHitSkeleton: React.FC<OccurrenceSearchHitProps> = () => {
  return <SearchHitTemplateSkeleton />
}

export default Relay.withSkeleton<OccurrenceSearchHitProps>({
  component: OccurrenceSearchHit,
  skeleton: OccurrenceSearchHitSkeleton,
})
