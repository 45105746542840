import { decomposeColor, hexToRgb } from "@material-ui/core/styles"

/** Returns true if the string is a valid color */
export function isValidColor(color: string | undefined | null): color is string {
  if (!color) return false
  try {
    decomposeColor(color)
    return true
  } catch {
    return false
  }
}

export function isHexColor(value: unknown): boolean {
  // matches #RGB, #RRGGBB, #RGBA and #RRGGBBAA
  return typeof value === "string" && /^#([0-9A-F]{3,4}){1,2}$/i.test(value)
}

export function rgbToHex(rgb: [number, number, number]) {
  return `#${rgb
    .map((x) => {
      const hex = x.toString(16)
      return hex.padStart(2, "0")
    })
    .join("")}`
}

/** Add opacity to a hex color, specified as a decimal between 0 and 1 */
export function hexOpacity(hexColor: string, opacity: number): string {
  const decimal = Math.max(Math.min(opacity, 1), 0)
  const hex = Math.round(decimal * 255)
    .toString(16)
    .padStart(2, "0")
  return `${hexColor}${hex}`
}

// https://stackoverflow.com/questions/3732046/how-do-you-get-the-hue-of-a-xxxxxx-colour
function rgbToHsl(rgb: [number, number, number]) {
  const r = rgb[0] / 255
  const g = rgb[1] / 255
  const b = rgb[2] / 255
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = (max + min) / 2
  let s = (max + min) / 2
  const l = (max + min) / 2

  if (max === min) {
    // achromatic
    h = 0
    s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h = h / 6
  }
  return [Math.round(h * 100), Math.round(s * 100), Math.round(l * 100)]
}

export function hexToHsl(color: string) {
  const rgb = hexToRgb(color)
  const regex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*\d+\.?\d*)?\)$/
  const [_, r, g, b] = rgb.match(regex)!
  return rgbToHsl([Number(r), Number(g), Number(b)])
}
