import useStartDirectMessage from "@/chat/hooks/useStartDirectMessage"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import useMemberGroupTagsList from "@/product/common/member-group/common/tag/useMemberGroupTagsList"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import OrganizationRoleTag from "@/role/OrganizationRoleTag"
import ProductRoleTag from "@/role/ProductRoleTag"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import { PROFILE_POPOVER_WIDTH } from "@/user/common/profile-popover/ProfilePopover"
import { ProfilePopoverFieldsQuery } from "@/user/common/profile-popover/__generated__/ProfilePopoverFieldsQuery.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoButtonSkeleton, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoTag, { DiscoTagSkeleton } from "@disco-ui/tag/DiscoTag"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  userId: GlobalID
  onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void
  hideButtons?: boolean
  truncateBioText?: number
  bioTextPlaceholder?: boolean
  className?: string
}

function ProfilePopoverFields(props: Props) {
  const {
    userId,
    onClose,
    hideButtons,
    className: customClassName,
    truncateBioText = 3,
    bioTextPlaceholder = false,
    testid = "ProfilePopoverFields",
  } = props
  const classes = useStyles()
  const { authUser } = useAuthUser()
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const settingsDrawer = useGlobalDrawer("profileSettings")

  const { node } = useLazyLoadQuery<ProfilePopoverFieldsQuery>(
    graphql`
      query ProfilePopoverFieldsQuery(
        $userId: ID!
        $productId: ID!
        $organizationId: ID!
      ) {
        node(id: $userId) {
          __typename
          ... on User {
            id
            fullName
            bio
            ...ProfileAvatarFragment
            organizationMembership(organizationId: $organizationId) {
              id
              role
              ...useMemberGroupTagsList_OrganizationMembershipFragment
            }
            productMembership(productId: $productId) {
              id
              role
            }
          }
        }
      }
    `,
    {
      userId,
      productId: activeProduct?.id || "",
      organizationId: activeOrganization.id,
    }
  )
  const user = Relay.narrowNodeType(node, "User")

  const { everyoneTags, adminOnlyTags } = useMemberGroupTagsList({
    organizationMembershipKey: user?.organizationMembership,
    linkToMembers: true,
  })
  const groupTags = everyoneTags.concat(adminOnlyTags)
  const [startDirectMessage, isStartingDM] = useStartDirectMessage()

  if (!user) return null

  const isSelfViewing = user.id === authUser?.id

  const { fullName, productMembership, organizationMembership } = user
  const hasBioPlaceholder = bioTextPlaceholder && !user.bio
  const productRole = productMembership?.role
  const organizationRole = organizationMembership?.role

  return (
    <div className={classNames(classes.root, customClassName)}>
      {/** Avatar and details */}
      <div className={classes.container} data-testid={testid}>
        <ProfileAvatar testid={`${testid}.ProfileAvatar`} userKey={user} size={64} />
        <div>
          <DiscoText
            variant={"body-md-600"}
            testid={`${testid}.member-fullname`}
            marginBottom={0.5}
            truncateText={1}
            title={fullName}
            // Hide name in FS recordings
            className={classNames("fs-mask", classes.fullName)}
          >
            {fullName}
          </DiscoText>
          <div>
            {productRole === "manager" || productRole === "instructor" ? (
              <ProductRoleTag productRole={productRole} />
            ) : organizationRole ? (
              <OrganizationRoleTag organizationRole={organizationRole} />
            ) : null}
          </div>
        </div>
      </div>

      {/** Body */}
      {(user.bio || hasBioPlaceholder) && (
        <div>
          <DiscoText variant={"body-sm-700"}>{"Bio"}</DiscoText>
          <DiscoText
            variant={"body-sm"}
            truncateText={truncateBioText}
            fontStyle={hasBioPlaceholder ? "italic" : undefined}
            color={hasBioPlaceholder ? "groovy.neutral.400" : "text.secondary"}
          >
            {user.bio || "No bio added"}
          </DiscoText>
        </div>
      )}

      {/** Groups */}
      {activeProduct?.hasMemberGroups && groupTags.length > 0 && (
        <div>
          <DiscoText variant={"body-sm-700"} marginBottom={1}>
            {"Groups"}
          </DiscoText>
          <div className={classes.userTagsContainer}>
            {/* Render up to 4 total tags */}
            {groupTags.length <= 4 ? (
              groupTags
            ) : (
              <>
                {groupTags.slice(0, 3)}
                <DiscoTag name={`+ ${groupTags.length - 3} more`} />
              </>
            )}
          </div>
        </div>
      )}

      {/** DM button */}
      {organizationMembership && !hideButtons && (
        <div className={classes.buttonsContainer}>
          <DiscoButton
            onClick={handleOpenProfile}
            color={"grey"}
            variant={"outlined"}
            data-testid={`${testid}.view-details-button`}
          >
            {"View Details"}
          </DiscoButton>
          {!isSelfViewing && activeOrganization.isDmEnabled && (
            <DiscoButton
              data-testid={`${testid}.send-message-button`}
              shouldDisplaySpinner={isStartingDM}
              width={"100%"}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                if (!organizationMembership) return
                startDirectMessage([organizationMembership.id])
                // Close the popover after starting the DM
                onClose(event)
              }}
            >
              {"Message"}
            </DiscoButton>
          )}
        </div>
      )}
    </div>
  )

  function handleOpenProfile(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()
    if (!Relay.isNodeType(user, "User")) return

    onClose()
    settingsDrawer.open({
      drawerProfileId: user.id,
      profileSettingsTab: "profile",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "64px 1fr",
    gap: theme.spacing(1.5),
    alignItems: "center",
    minWidth: "auto",
  },
  buttonsContainer: {
    display: "flex",
    gap: theme.spacing(1.25),
    "& > button": {
      flex: 1,
    },
  },
  userTagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    overflow: "hidden",
  },
  fullName: {
    width: `${PROFILE_POPOVER_WIDTH - 120}px`,
  },
}))

function ProfilePopoverFieldsSkeleton() {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.container}>
        <Skeleton variant={"circle"} width={64} height={64} />
        <div>
          <DiscoTextSkeleton variant={"body-md-600"} marginBottom={0.5} />
          <DiscoTagSkeleton />
        </div>
      </div>

      <div>
        <DiscoTextSkeleton variant={"body-sm-700"} width={"50px"} />
        <DiscoTextSkeleton variant={"body-sm"} />
        <DiscoTextSkeleton variant={"body-sm"} />
      </div>

      <div className={classes.buttonsContainer}>
        <DiscoButtonSkeleton width={"100px"} />
        <DiscoButtonSkeleton width={"100px"} />
      </div>
    </div>
  )
}

export default Relay.withSkeleton({
  component: ProfilePopoverFields,
  skeleton: ProfilePopoverFieldsSkeleton,
})
