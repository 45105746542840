import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useSearchContext } from "@/core/context/SearchContext"
import useTrackUserActivity from "@/reporting/useTrackUserActivity"
import SearchFiltersItem from "@/search/SearchFiltersItem"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoInput } from "@disco-ui"
import useDebounce from "@utils/hook/useDebounce"
import { useState } from "react"

function SearchInput() {
  const { query, refine, closeSearch, appliedFilters } = useSearchContext()
  const activeOrganization = useActiveOrganization()!
  const trackSearch = useTrackUserActivity()
  const [search, setSearch] = useState(query)
  const classes = useStyles()

  const debouncedSearch = useDebounce((s: string) => {
    trackSearch({
      kind: "search",
      entityId: activeOrganization.id,
      metadata: { search: s },
    })
  }, 5000)

  return (
    <DiscoInput
      value={search}
      className={classes.input}
      onChange={handleChange}
      placeholder={"Search"}
      fullWidth
      data-testid={"DiscoSearchBox"}
      padding={"0 10px 0 10px"}
      minHeight={"40px"}
      startAdornment={renderAdornment()}
      disableFocus
      disableHover
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      // Select the input text when the search modal is opened
      onFocus={(e) => e.target.select()}
      // If escape is pressed, close the search modal
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.preventDefault() // If esc key is pressed when search modal is open, don't minimize full screen in MacOS
          closeSearch?.()
        }
      }}
    />
  )

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value)
    refine(e.target.value)
    debouncedSearch(search)
  }

  function renderAdornment() {
    if (appliedFilters.length) return renderRefinements()
    return <DiscoIcon icon={"search"} paddingRight={0.5} />
  }

  function renderRefinements() {
    return (
      <div className={classes.filters}>
        {appliedFilters.map((type) => (
          <SearchFiltersItem key={type} type={type} />
        ))}
      </div>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  filters: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  input: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}))

export default SearchInput
