import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { CreateSlackUserImportButtonMutation } from "@/slack/create-slack-user-import-button/__generated__/CreateSlackUserImportButtonMutation.graphql"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  children: OverridableDiscoButtonChildren
  onSuccess: () => void
}

function CreateSlackUserImportButton(props: Props) {
  const { children, onSuccess, testid = "CreateSlackUserImportButton" } = props
  const activeOrganization = useActiveOrganization()!

  const form = useFormStore<CreateSlackUserImportButtonMutation>(
    graphql`
      mutation CreateSlackUserImportButtonMutation($input: CreateSlackUserImportInput!) {
        response: createSlackUserImport(input: $input) {
          node {
            id
            status
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
    }
  )

  return (
    <OverridableDiscoButton
      testid={testid}
      onClick={handleSubmit}
      disabled={form.isSubmitting}
    >
      {children}
    </OverridableDiscoButton>
  )

  async function handleSubmit() {
    const { didSave, response } = await form.submit(form.state, {
      updater: (store, payload) => {
        const organizationStore = store.get(activeOrganization.id)

        if (!organizationStore || !payload.response.node) return

        // update the active import status in store / ActiveSlackUserImportContext
        const slackImport = organizationStore.getLinkedRecord("slackUserImport")
        if (!slackImport) return
        slackImport.setValue(payload.response.node.status, "status")
      },
    })

    if (!didSave || !response?.node) return

    onSuccess()
  }
}

export default CreateSlackUserImportButton
