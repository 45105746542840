/**
 * @generated SignedSource<<28012ef140ffac2e6e584df468bf0f0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsDetailsTabQuery$variables = {
  userId: string;
  organizationId: string;
};
export type ProfileSettingsDetailsTabQuery$data = {
  readonly user: {
    readonly organizationMembership?: {
      readonly id: string;
      readonly email: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsCustomFieldsFragment">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsUserSectionFragment">;
  } | null;
};
export type ProfileSettingsDetailsTabQuery = {
  variables: ProfileSettingsDetailsTabQuery$variables;
  response: ProfileSettingsDetailsTabQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "isPrivate",
  "value": true
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "kind": "Literal",
  "name": "status",
  "value": "visible"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileSettingsDetailsTabQuery",
    "selections": [
      {
        "alias": "user",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "organizationMembership",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": (v7/*: any*/),
                    "kind": "FragmentSpread",
                    "name": "ProfileSettingsCustomFieldsFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "args": (v3/*: any*/),
                "kind": "FragmentSpread",
                "name": "ProfileSettingsUserSectionFragment"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProfileSettingsDetailsTabQuery",
    "selections": [
      {
        "alias": "user",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "OrganizationMembership",
                "kind": "LinkedField",
                "name": "organizationMembership",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "publicCustomFields",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "isDefault",
                            "value": false
                          },
                          (v6/*: any*/),
                          (v8/*: any*/)
                        ],
                        "concreteType": "ProfileFieldNodeConnection",
                        "kind": "LinkedField",
                        "name": "profileFields",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileFieldNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProfileField",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "WebFormQuestion",
                                    "kind": "LinkedField",
                                    "name": "webFormQuestion",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "type",
                                        "storageKey": null
                                      },
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "profileFields(isDefault:false,isPrivate:true,status:\"visible\")"
                      },
                      (v4/*: any*/),
                      {
                        "alias": "defaultFields",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "isDefault",
                            "value": true
                          },
                          (v8/*: any*/)
                        ],
                        "concreteType": "ProfileFieldNodeConnection",
                        "kind": "LinkedField",
                        "name": "profileFields",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileFieldNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProfileField",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isPrivate",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "profileFields(isDefault:true,status:\"visible\")"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "displayProfileValues",
                    "args": (v7/*: any*/),
                    "concreteType": "ProfileValueNodeConnection",
                    "kind": "LinkedField",
                    "name": "profileValues",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProfileValueNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileValue",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profileFieldId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "profileValues(isPrivate:true)"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bio",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "websiteUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezone",
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "13de68cf2a2d1effa4c1999e048ab83f",
    "id": null,
    "metadata": {},
    "name": "ProfileSettingsDetailsTabQuery",
    "operationKind": "query",
    "text": "query ProfileSettingsDetailsTabQuery(\n  $userId: ID!\n  $organizationId: ID!\n) {\n  user: node(id: $userId) {\n    __typename\n    ... on User {\n      organizationMembership(organizationId: $organizationId) {\n        id\n        email\n        ...ProfileSettingsCustomFieldsFragment_XGSzN\n      }\n      ...ProfileSettingsUserSectionFragment_4xMPKw\n    }\n    id\n  }\n}\n\nfragment ProfileSettingsCustomFieldsFragment_XGSzN on OrganizationMembership {\n  organization {\n    publicCustomFields: profileFields(status: visible, isPrivate: true, isDefault: false) {\n      edges {\n        node {\n          id\n          title\n          webFormQuestion {\n            type\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n  displayProfileValues: profileValues(isPrivate: true) {\n    edges {\n      node {\n        id\n        profileFieldId\n        value\n      }\n    }\n  }\n}\n\nfragment ProfileSettingsUserSectionFragment_4xMPKw on User {\n  id\n  bio\n  websiteUrl\n  timezone\n  organizationMembership(organizationId: $organizationId) {\n    id\n    organization {\n      defaultFields: profileFields(status: visible, isDefault: true) {\n        edges {\n          node {\n            id\n            title\n            isPrivate\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7b2c4f3e567c05f80539d7e9611d34e";

export default node;
