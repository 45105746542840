import Badge from "@/admin/experiences/badges/Badge"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import { CertificateCardFragment$key } from "@/user/settings/subtabs/profile/certificates/__generated__/CertificateCardFragment.graphql"
import { DiscoButton, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { graphql, useFragment } from "react-relay"

interface Props {
  userCertificateKey: CertificateCardFragment$key
  testid: string
}

function CertificateCard({ userCertificateKey, testid }: Props) {
  const { isSelfViewing } = useMemberProfileContext()!
  const classes = useStyles()

  const userCertificate = useFragment<CertificateCardFragment$key>(
    graphql`
      fragment CertificateCardFragment on UserCertificate {
        id
        mediaUrl
        product {
          name
          badge {
            ...BadgeFragment
          }
        }
        organization {
          name
          badge {
            ...BadgeFragment
          }
        }
      }
    `,
    userCertificateKey
  )

  const { mediaUrl, product, organization } = userCertificate

  return (
    <Grid item>
      <div data-testid={testid} className={classes.cardContainer}>
        <div className={classes.titleArea}>
          <Badge badgeKey={product?.badge || organization.badge!} size={24} />
          <DiscoText marginBottom={2} color={"text.secondary"}>
            {product?.name || organization.name}
          </DiscoText>
        </div>
        <div className={classes.previewContainer}>
          <img className={classes.previewImage} src={mediaUrl} alt={""} />
        </div>
        {isSelfViewing && (
          <div className={classes.footerContainer}>
            <DiscoButton onClick={handleDownload}>{"Download"}</DiscoButton>
          </div>
        )}
      </div>
    </Grid>
  )

  function handleDownload() {
    window.location.href = encodeURI(userCertificate.mediaUrl)
  }
}

const useStyles = makeUseStyles((theme) => ({
  cardContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  previewContainer: {
    marginBottom: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.neutral[100],
    padding: theme.spacing(3.5, 5),
    overflow: "hidden",
  },
  titleArea: {
    display: "flex",
    gap: theme.spacing(1),
  },
  previewImage: {
    width: "100%",
    borderRadiusTopLeft: theme.measure.borderRadius.default,
    borderRadiusTopRight: theme.measure.borderRadius.default,
    boxShadow: theme.palette.groovyDepths.boxShadow,
    objectFit: "cover",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))

export const CertificateCardSkeleton: React.FC = () => {
  const classes = useStyles()
  return (
    <Grid item>
      <div className={classes.cardContainer}>
        <Skeleton height={390} width={"100%"} />
      </div>
    </Grid>
  )
}

export default CertificateCard
