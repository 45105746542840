import { useLabel } from "@/core/context/LabelsContext"
import ProfileSettingsEventRegistrationTable, {
  ProfileSettingsEventRegistrationTableSkeleton,
} from "@/user/settings/subtabs/registrations/table/ProfileSettingsEventRegistrationTable"
import ProfileSettingsProductRegistrationTable, {
  ProfileSettingsProductRegistrationTableSkeleton,
} from "@/user/settings/subtabs/registrations/table/ProfileSettingsProductRegistrationTable"
import { DiscoSection, DiscoText, DiscoTextSkeleton } from "@disco-ui"

function ProfileSettingsRegistrationsTab() {
  const experienceLabel = useLabel("experience")

  return (
    <DiscoSection padding={0}>
      <DiscoText variant={"body-md-600"} marginBottom={1.5}>
        {"Events"}
      </DiscoText>
      <ProfileSettingsEventRegistrationTable />

      <DiscoText variant={"body-md-600"} marginTop={3} marginBottom={1.5}>
        {experienceLabel.plural}
      </DiscoText>
      <ProfileSettingsProductRegistrationTable />
    </DiscoSection>
  )
}

export const ProfileSettingsRegistrationsTabSkeleton = () => {
  return (
    <DiscoSection padding={0}>
      <DiscoTextSkeleton variant={"body-md-600"} marginBottom={1.5} width={100} />
      <ProfileSettingsEventRegistrationTableSkeleton />

      <DiscoTextSkeleton
        variant={"body-md-600"}
        marginTop={3}
        marginBottom={1.5}
        width={100}
      />
      <ProfileSettingsProductRegistrationTableSkeleton />
    </DiscoSection>
  )
}

export default ProfileSettingsRegistrationsTab
