import { ApiRangeModel } from "../../core/network-manager/networkModels"
import { throwError } from "../error/errorUtils"

function getRange(from: number, to: number, step = 1) {
  const [min, max] = [Math.min(from, to), Math.max(from, to)]
  let stepNumber = step

  if (step < 1) {
    stepNumber = 1
  }

  const arr = []
  let i = min

  while (i <= max) {
    arr.push(i)
    i += stepNumber
  }

  return arr
}

// Produce an array of provided length, with values set to the index
function range(length: number): number[] {
  return [...Array(length)].map((_, i) => i)
}

function sumValues(array: number[]) {
  return array.reduce((total, entry) => {
    return total + entry
  }, 0)
}

function moveItemWithinArray<Item>(array: Item[], from: number, to: number): Item[] | [] {
  const item = array[from]

  return addItemAtAnIndex(removeFromArrayByIndex(array, from), item, to)
}

function sumValuesOfObjects<T extends { [x: string]: any }>(
  array: T[],
  keyToSum: keyof T
) {
  const firstValue = array[0] && parseFloat(array[0][keyToSum])

  if ((array[0] && typeof firstValue !== "number") || Object.is(firstValue, NaN)) {
    throwError("Value of `keyToSum` should be able to be parsed as a real number")
  }

  return array.reduce((total, entry) => {
    return total + parseFloat(entry[keyToSum])
  }, 0)
}

function getStringifiedRange(
  from: number,
  to: number,
  padProps?: { padWith: string; padLength: number }
) {
  return getRange(from, to).map((num) => {
    let stringifiedNum = String(num)

    if (padProps) {
      const { padWith, padLength } = padProps

      stringifiedNum = stringifiedNum.padStart(padLength, padWith)
    }

    return stringifiedNum
  })
}

function findItemInArray<T>(
  array: T[] | null,
  keyToLookFor: keyof T,
  valueToLookFor: unknown
) {
  return (
    array &&
    array.find((item) => {
      const itemValue = item[keyToLookFor]

      if (typeof itemValue === "string" && typeof valueToLookFor === "string") {
        return itemValue.includes(valueToLookFor)
      }

      return itemValue === valueToLookFor
    })
  )
}

function isItemInArray<T>(
  array: T[] | null,
  keyToLookFor: keyof T,
  valueToLookFor: unknown
) {
  return Boolean(array && array.some((item) => item[keyToLookFor] === valueToLookFor))
}

function getSecondLastItem<T>(array?: T[] | null | undefined) {
  // eslint-disable-next-line
  return array && array.length > 1 ? array[array.length - 2] : null
}

function getThirdLastItem<T>(array: T[] | null | undefined) {
  // eslint-disable-next-line
  return array && array.length > 2 ? array[array.length - 3] : null
}

function replaceInArray<T = unknown>(originalArray: T[], newItem: T, index: number): T[] {
  const newArray = [...originalArray]

  newArray.splice(index, 1, newItem)

  return newArray
}

function addItemAtAnIndex<T = unknown>(
  originalArray: T[],
  newItem: T,
  index: number
): T[] {
  const newArray = [...originalArray]

  newArray.splice(index, 0, newItem)

  return newArray
}

function removeFromArrayByIndex<T = unknown>(originalArray: T[], index: number): T[] {
  const newArray = [...originalArray]

  newArray.splice(index, 1)

  return newArray
}

function removeDuplicatesFromArrayOfObjects<T = unknown>(
  originalArray: T[],
  propertyName: keyof T
): T[] {
  return originalArray.reduce<T[]>((unique, item) => {
    const itemIndex = unique.findIndex(
      (uniqueItem) =>
        Boolean(uniqueItem[propertyName] && item[propertyName]) &&
        uniqueItem[propertyName] === item[propertyName]
    )

    return itemIndex === -1 ? [...unique, item] : replaceInArray(unique, item, itemIndex)
  }, [])
}

function filterOutItems<T extends string | number>(array: T[], items: T[]): T[] {
  return array.filter((item) => !items.includes(item))
}

function filterOutItemsByKey<T extends { [x: string]: any }>(
  array: T[],
  key: keyof T,
  items: T[]
): T[] {
  const itemsToFilterOut = items.map((item) => item[key])

  return array.reduce<T[]>((finalArray, item) => {
    if (!itemsToFilterOut.includes(item[key])) {
      finalArray.push(item)
    }

    return finalArray
  }, [])
}

function filterInItemsByKey<T extends { [x: string]: any }>(
  array: T[],
  key: keyof T,
  items: T[]
): T[] {
  const itemsToFilterIn = items.map((item) => item[key])

  return array.reduce<T[]>((finalArray, item) => {
    if (itemsToFilterIn.includes(item[key])) {
      finalArray.push(item)
    }

    return finalArray
  }, [])
}

function reverseArray<T>(array: T[]): T[] {
  return [...array].reverse()
}

function filterArrayByValue<T>(
  array: T[] | null,
  keyToLookFor: keyof T,
  valueToLookFor: unknown
): T[] {
  return array
    ? array.filter((item) => {
        const itemValue = item[keyToLookFor]

        if (typeof itemValue === "string" && typeof valueToLookFor === "string") {
          return itemValue.toLowerCase().includes(valueToLookFor.toLowerCase())
        }

        return itemValue === valueToLookFor
      })
    : []
}

function isNonEmptyArray<T extends undefined | null | any[]>(
  array: T
): array is NonNullable<T> {
  return Boolean(array && array.length)
}

function isArrayOfStrings(array: unknown): array is string[] {
  return Array.isArray(array) && array.every((item) => typeof item === "string")
}

function generateArrayFromRange(r: ApiRangeModel): number[] {
  return new Array(r.upper - r.lower + 1).fill(0).map(
    (
      // @ts-ignore
      item,
      index
    ) => r.lower + index
  )
}

function getFirstItemByKey<T extends { [x: string]: any }>(items: T[], key: keyof T) {
  let item

  for (const entry of items) {
    if (entry[key]) {
      item = entry
      break
    }
  }

  return item
}

export namespace ArrayUtils {
  /** Return the added and removed items between two arrays */
  export function diff<T>(initial: T[], current: T[]): { added: T[]; removed: T[] } {
    const initialSet = new Set(initial)
    const currentSet = new Set(current)
    return {
      added: current.filter((v) => !initialSet.has(v)),
      removed: initial.filter((v) => !currentSet.has(v)),
    }
  }

  /** Map object of arrays by a key */
  export function mapBy<T extends Record<string, any>, K extends keyof T>(
    arr: Array<T>,
    key: K
  ): Record<T[K], T> {
    return arr.reduce((acc, item) => {
      acc[item[key]] = item
      return acc
    }, {} as Record<T[K], T>)
  }

  /** Conditionally insert a value in an array for conditional spread:
   * ...ArrayUtils.spreadIf(value, condition)
   */
  export function spreadIf<T>(value: T[], condition: any): T[]
  export function spreadIf<T>(value: T, condition: any): [] | [T]
  export function spreadIf<T>(value: T | T[], condition: any) {
    return condition ? (Array.isArray(value) ? value : [value]) : []
  }

  /**
   * Split an array into chunks of size n
   * chunks([1, 2, 3], 2) => [[1, 2], [3]]
   */
  export function chunks<T>(arr: T[], n: number): T[][] {
    const chunked: T[][] = []
    for (let i = 0; i < arr.length; i += n) {
      chunked.push(arr.slice(i, i + n))
    }
    return chunked
  }

  export function shuffleArray<T>(arr: T[]) {
    return arr
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
  }
}

export {
  getRange,
  range,
  sumValues,
  sumValuesOfObjects,
  getStringifiedRange,
  moveItemWithinArray,
  findItemInArray,
  isItemInArray,
  isArrayOfStrings,
  getSecondLastItem,
  replaceInArray,
  removeFromArrayByIndex,
  removeDuplicatesFromArrayOfObjects,
  filterOutItems,
  filterOutItemsByKey,
  filterInItemsByKey,
  reverseArray,
  filterArrayByValue,
  isNonEmptyArray,
  addItemAtAnIndex,
  getThirdLastItem,
  generateArrayFromRange,
  getFirstItemByKey,
}
