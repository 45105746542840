/**
 * @generated SignedSource<<b51d9d5de74e94917622673ef35dbf01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDropdownItemFragment$data = {
  readonly id: string;
  readonly avatar: string;
  readonly fullName: string;
  readonly first_name: string | null;
  readonly last_name: string | null;
  readonly " $fragmentType": "UserDropdownItemFragment";
};
export type UserDropdownItemFragment$key = {
  readonly " $data"?: UserDropdownItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDropdownItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": "first_name",
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": "last_name",
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "15b271fe3cd4a5e1e48fa2395ae3a842";

export default node;
