import SearchFilters from "@/search/SearchFilters"
import SearchInput from "@/search/SearchInput"
import SearchHits from "@/search/search-hits/SearchHits"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModal, DiscoModalProps } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

type Props = DiscoModalProps

function SearchModal(props: Props) {
  const { onClose, ...rest } = props
  const classes = useStyles()
  const history = useHistory()

  // If the url changes, close the modal
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (onClose) onClose()
    })
    return () => {
      unlisten()
    }
  }, [history, onClose])

  return (
    <DiscoModal
      {...rest}
      buttons
      width={"635px"}
      title={
        <>
          <SearchInput />
          <SearchFilters />
        </>
      }
      body={<SearchHits />}
      modalContentLabel={"Search Modal"}
      testid={"SearchModal"}
      onClose={onClose}
      maxHeight={"80%"}
      minHeight={"0px"}
      hideCloseIcon={true}
      classes={{
        container: classes.container,
        headerRow: classes.headerRow,
        body: classes.body,
      }}
      hideFooter
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    top: "auto",
    transform: "translate(-50%, 0%)",
    marginTop: theme.spacing(12),
    borderRadius: theme.measure.borderRadius.big,
    border:
      theme.palette.type === "dark"
        ? `1.5px solid ${theme.palette.groovy.onDark[400]}`
        : "",
  },
  headerRow: {
    padding: 0,
  },
  body: {
    padding: 0,
  },
}))

export default observer(SearchModal)
