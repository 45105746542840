/**
 * @generated SignedSource<<a750cac5858c0162bbbe93794be808e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsProductRegistrationTableRowFragment$data = {
  readonly name: string;
  readonly type: ProductType;
  readonly slug: string;
  readonly startsAt: string | null;
  readonly endsAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProductTypeTagFragment" | "ProductWithDetailsFragment">;
  readonly " $fragmentType": "ProfileSettingsProductRegistrationTableRowFragment";
};
export type ProfileSettingsProductRegistrationTableRowFragment$key = {
  readonly " $data"?: ProfileSettingsProductRegistrationTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsProductRegistrationTableRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsProductRegistrationTableRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductTypeTagFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductWithDetailsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "1859b2bef574f0f9fbf9b56eea47f801";

export default node;
