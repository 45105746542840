import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import IconWithContent from "@/user/settings/common/icon-with-content/IconWithContent"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import ProfileSettingsDetailsTabNotes, {
  ProfileSettingsDetailsTabNotesSkeleton,
} from "@/user/settings/subtabs/details/ProfileSettingsDetailsTabNotes"
import { ProfileSettingsDetailsTabQuery } from "@/user/settings/subtabs/details/__generated__/ProfileSettingsDetailsTabQuery.graphql"
import ProfileSettingsCustomFields, {
  ProfileSettingsCustomFieldsSkeleton,
} from "@/user/settings/subtabs/profile/ProfileSettingsCustomFields"
import ProfileSettingsUserSection from "@/user/settings/subtabs/profile/ProfileSettingsUserSection"
import {
  DiscoAlert,
  DiscoDivider,
  DiscoSection,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { useLazyLoadQuery } from "react-relay"
import { Props, graphql } from "relay-runtime"

function ProfileSettingsDetailsTab() {
  const { profile } = useMemberProfileContext()!
  const isMobile = useIsMobile()
  const activeOrganization = useActiveOrganization()

  const { user } = useLazyLoadQuery<ProfileSettingsDetailsTabQuery>(
    graphql`
      query ProfileSettingsDetailsTabQuery($userId: ID!, $organizationId: ID!) {
        user: node(id: $userId) {
          ... on User {
            organizationMembership(organizationId: $organizationId) {
              id
              email
              ...ProfileSettingsCustomFieldsFragment @arguments(isPrivate: true)
            }
            ...ProfileSettingsUserSectionFragment
              @arguments(organizationId: $organizationId)
          }
        }
      }
    `,
    { userId: profile.id, organizationId: activeOrganization?.id || "" },
    { fetchPolicy: "store-and-network" }
  )

  if (!user) return null
  const membership = user.organizationMembership

  return (
    <DiscoSection
      padding={0}
      paddingLeft={isMobile ? 2 : 4}
      paddingRight={isMobile ? 2 : 4}
    >
      <DiscoAlert message={"This tab is visible to Admins only"} marginBottom={2} />
      <IconWithContent
        icon={"email"}
        content={
          <DiscoText
            color={"text.secondary"}
            testid={"ProfileSettingsDetailsTab.email"}
            // Hide email in FS recordings
            className={"fs-mask"}
          >
            {membership?.email}
          </DiscoText>
        }
      />
      <ProfileSettingsUserSection userKey={user} isDetailsTab />
      {membership && (
        <ProfileSettingsCustomFields organizationMembershipKey={membership} />
      )}
      <DiscoDivider marginTop={2.5} marginBottom={2.5} />
      <ProfileSettingsDetailsTabNotes testid={"ProfileSettingsDetailsTab"} />
    </DiscoSection>
  )
}

export const ProfileSettingsDetailsTabSkeleton: React.FC = () => (
  <>
    <DiscoTextSkeleton width={"30%"} />
    <DiscoTextSkeleton width={"60%"} />
    <ProfileSettingsCustomFieldsSkeleton />
    <DiscoDivider />
    <ProfileSettingsDetailsTabNotesSkeleton />
  </>
)

export default Relay.withSkeleton<Props>({
  component: ProfileSettingsDetailsTab,
  skeleton: ProfileSettingsDetailsTabSkeleton,
})
