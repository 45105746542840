import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useSearchContext } from "@/core/context/SearchContext"
import { SearchType, SearchTypeLabels } from "@/search/utils/searchUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoIcon, DiscoText } from "@disco-ui"

type SearchFiltersItemProps = {
  type: SearchType
}

function SearchFiltersItem({ type }: SearchFiltersItemProps) {
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const { filter, appliedFilters } = useSearchContext()
  const isSelected = appliedFilters?.includes(type)

  if (!activeOrganization?.hasBackfilledStreamMessages && type === "chatMessage") {
    return null
  }

  return (
    <DiscoButton
      variant={"outlined"}
      color={"transparent"}
      onClick={handleSelectFilter}
      className={classes.button}
      classes={{
        rightIcon: classes.rightIcon,
      }}
      rightIcon={isSelected ? <DiscoIcon icon={"close"} /> : undefined}
    >
      <DiscoText variant={"body-sm-500"} color={"text.secondary"}>
        {SearchTypeLabels[type]}
      </DiscoText>
    </DiscoButton>
  )

  function handleSelectFilter() {
    filter(type)
  }
}

const useStyles = makeUseStyles((theme) => ({
  button: {
    border: `1.5px solid ${theme.palette.groovy.neutral[300]}`,
    height: theme.spacing(3.5),
    padding: theme.spacing(0.75),
  },
  rightIcon: {
    width: "20px",
    height: "20px",

    "& > svg": {
      color: theme.palette.groovy.neutral[400],
      width: "20px",
      height: "20px",
    },
  },
}))

export default SearchFiltersItem
