import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import ChatChannelSearchHit, {
  ChatChannelSearchHitSkeleton,
  ChatChannelHit,
} from "@/search/search-hits/kinds/ChatChannelSearchHit"
import ChatMessageSearchHit, {
  ChatMessageHit,
  ChatMessageSearchHitSkeleton,
} from "@/search/search-hits/kinds/ChatMessageSearchHit"
import ContentUsageSearchHit, {
  ContentUsageHit,
  ContentUsageSearchHitSkeleton,
} from "@/search/search-hits/kinds/ContentUsageSearchHit"
import FeedSearchHit, {
  FeedHit,
  FeedSearchHitSkeleton,
} from "@/search/search-hits/kinds/FeedSearchHit"
import OccurrenceSearchHit, {
  OccurrenceHit,
  OccurrenceSearchHitSkeleton,
} from "@/search/search-hits/kinds/OccurrenceSearchHit"
import PostSearchHit, {
  PostHit,
  PostSearchHitSkeleton,
} from "@/search/search-hits/kinds/PostSearchHit"
import ProductSearchHit, {
  ProductHit,
  ProductSearchHitSkeleton,
} from "@/search/search-hits/kinds/ProductSearchHit"
import UserSearchHit, {
  UserHit,
  UserSearchHitSkeleton,
} from "@/search/search-hits/kinds/UserSearchHit"
import { TestIDProps } from "@utils/typeUtils"
import { BaseHit, Hit } from "instantsearch.js"

export interface SearchHitType extends Hit<BaseHit>, HitTypes {
  id: GlobalID
}

interface SearchHitProps extends TestIDProps {
  hit: SearchHitType
}

function SearchHit(props: SearchHitProps) {
  const { hit } = props

  const hitConfig = HIT_COMPONENTS[hit.type as keyof typeof HIT_COMPONENTS]
  if (!hitConfig) return null

  const Component = hitConfig.main
  if (!Component) return null

  return <Component hit={hit} />
}

const HIT_COMPONENTS = {
  post: {
    main: PostSearchHit,
    skeleton: PostSearchHitSkeleton,
  },
  user: {
    main: UserSearchHit,
    skeleton: UserSearchHitSkeleton,
  },
  product: {
    main: ProductSearchHit,
    skeleton: ProductSearchHitSkeleton,
  },
  chatChannel: {
    main: ChatChannelSearchHit,
    skeleton: ChatChannelSearchHitSkeleton,
  },
  feed: {
    main: FeedSearchHit,
    skeleton: FeedSearchHitSkeleton,
  },
  contentUsage: {
    main: ContentUsageSearchHit,
    skeleton: ContentUsageSearchHitSkeleton,
  },
  occurrence: {
    main: OccurrenceSearchHit,
    skeleton: OccurrenceSearchHitSkeleton,
  },
  chatMessage: {
    main: ChatMessageSearchHit,
    skeleton: ChatMessageSearchHitSkeleton,
  },
}

type HitTypes = {
  post?: PostHit
  user?: UserHit
  product?: ProductHit
  chatChannel?: ChatChannelHit
  feed?: FeedHit
  occurrence?: OccurrenceHit
  contentUsage?: ContentUsageHit
  chatMessage?: ChatMessageHit
}

export const SearchHitSkeleton: React.FC<SearchHitProps> = ({ hit }) => {
  const hitConfig = HIT_COMPONENTS[hit.type as keyof typeof HIT_COMPONENTS]
  if (!hitConfig) return null

  const Component = hitConfig.skeleton
  if (!Component) return null

  return <Component hit={hit} />
}

export default Relay.withSkeleton({
  component: SearchHit,
  skeleton: SearchHitSkeleton,
})
