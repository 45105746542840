import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconButton, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { MouseEventHandler } from "react"

interface SearchHitCopyButtonProps extends TestIDProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  className?: string
}

function SearchHitCopyButton({ onClick, className }: SearchHitCopyButtonProps) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classNames(classes.actions, className)}>
      <DiscoTooltip content={"Copy link"}>
        <DiscoIconButton
          onClick={onClick}
          className={classes.iconButton}
          height={"32px"}
          width={"32px"}
          color={theme.palette.groovy.neutral[500]}
        >
          <DiscoIcon icon={"link"} />
        </DiscoIconButton>
      </DiscoTooltip>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  actions: {
    right: theme.spacing(2),
    zIndex: theme.zIndex.raise2,
  },
  iconButton: {
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.contrastText,
    border: `1.5px solid ${theme.palette.groovy.neutral[300]}`,
    borderRadius: theme.measure.borderRadius.medium,
  },
}))

export const SearchHitCopyButtonSkeleton: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.actions}>
      <Skeleton
        className={classes.iconButton}
        variant={"circle"}
        height={32}
        width={32}
      />
    </div>
  )
}

export default SearchHitCopyButton
