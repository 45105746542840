import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import { CreateSlackUserInviteButtonMutation } from "@/slack/slack-user-invite/__generated__/CreateSlackUserInviteButtonMutation.graphql"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButtonSkeleton } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

interface CreateSlackUserInviteButtonProps {
  children: OverridableDiscoButtonChildren
  onSuccess?: VoidFunction
  onSlackError?: (errors: string[]) => void
}

function CreateSlackUserInviteButton({
  children,
  onSuccess,
  onSlackError,
}: CreateSlackUserInviteButtonProps) {
  const activeOrganization = useActiveOrganization()!

  const form = useFormStore<CreateSlackUserInviteButtonMutation>(
    graphql`
      mutation CreateSlackUserInviteButtonMutation($input: CreateSlackUserInviteInput!) {
        response: createSlackUserInvite(input: $input) {
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
    }
  )

  return (
    <OverridableDiscoButton
      onClick={handleSubmit}
      disabled={form.isSubmitting}
      shouldDisplaySpinner={form.isSubmitting}
    >
      {children}
    </OverridableDiscoButton>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(form.state)
    if (!didSave) {
      const hasSlackErrors = form.errorsByField.slack?.length
      if (hasSlackErrors) {
        onSlackError?.(form.errorsByField.slack!)
      } else {
        displayErrorToast("Error creating slack invitation, please try again later.")
      }

      return
    }

    onSuccess?.()

    displaySuccessToast({
      message: "Slack invite sent",
      testid: "CreateSlackUserInviteButton.success-toast",
    })
  }
}

export default Relay.withSkeleton<CreateSlackUserInviteButtonProps>({
  component: observer(CreateSlackUserInviteButton),
  skeleton: () => <DiscoButtonSkeleton />,
})
