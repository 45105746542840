import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import LetterPostIcon from "@/core/ui/images/empty-state/letter-post.svg"
import Relay from "@/relay/relayUtils"
import UserNotesCreateForm, {
  UserNotesCreateFormSkeleton,
} from "@/user/notes/form/UserNotesCreateForm"
import UserNotesItem from "@/user/notes/list/item/UserNotesItem"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import { ProfileSettingsDetailsTabNotesFragment$key } from "@/user/settings/subtabs/details/__generated__/ProfileSettingsDetailsTabNotesFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoEmptyState, DiscoText, DiscoTextSkeleton, DiscoTooltip } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

function ProfileSettingsDetailsTabNotes(props: TestIDProps) {
  const { testid } = props
  const classes = useStyles()
  const labels = useLabels()
  const { profile, isSelfViewing } = useMemberProfileContext()!
  const activeOrganization = useActiveOrganization()

  const organizationMembership = useFragment<ProfileSettingsDetailsTabNotesFragment$key>(
    graphql`
      fragment ProfileSettingsDetailsTabNotesFragment on OrganizationMembership
      @argumentDefinitions(canViewNotes: { type: "Boolean!" }) {
        id
        notes @include(if: $canViewNotes) {
          totalCount
          __id
          edges {
            node {
              id
              ...UserNotesItemFragment
            }
          }
        }
      }
    `,
    profile.organizationMembership || null
  )

  if (
    !activeOrganization?.viewerPermissions.has("user_notes.read") ||
    isSelfViewing ||
    !profile.organizationMembership ||
    !organizationMembership
  )
    return null

  const notes = Relay.connectionToArray(organizationMembership.notes)

  return (
    <>
      <DiscoText
        variant={"body-md-600"}
        marginBottom={1}
        testid={`${testid}.notes-title`}
      >
        {"Admin Notes"}
        <DiscoTooltip
          content={`Notes are visible to community admins only. The user cannot view their own notes and other community ${labels.organization_member.plural}, including ${labels.product_admin.plural}, also cannot view or add notes either.`}
          buttonProps={{ classes: { root: classes.tooltipButton } }}
        />
      </DiscoText>
      <div
        // Key used to reset and clear the RichTextEditor in UserNotesCreateForm when a note is added
        key={`create-note-form-${organizationMembership.notes?.totalCount}`}
      >
        <UserNotesCreateForm
          organizationMembershipId={profile.organizationMembership.id}
          testid={`${testid}.create-form`}
          notesConnectionId={organizationMembership.notes!.__id}
        />
      </div>

      {notes.length === 0 ? (
        <DiscoEmptyState
          className={classes.emptyStateContainer}
          testid={"ProfileSettingsDetailsTabNotes"}
          subtitle={
            "There are no notes for this user. Notes are not visible to the user."
          }
          icon={<LetterPostIcon />}
          flow={"column-reverse"}
        />
      ) : (
        <div data-testid={`${testid}.notes-list`}>
          {notes.map((note, i) => (
            <UserNotesItem
              key={note.id}
              noteItemKey={note}
              testid={`${testid}.note.${i}`}
            />
          ))}{" "}
        </div>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  tooltipButton: {
    display: "inline",
    marginLeft: theme.spacing(0.75),
  },
  emptyStateContainer: {
    padding: 0,
    marginTop: theme.spacing(2),
  },
}))

export const ProfileSettingsDetailsTabNotesSkeleton: React.FC = () => (
  <>
    <DiscoTextSkeleton width={"30%"} />
    <UserNotesCreateFormSkeleton />
    <DiscoTextSkeleton />
    <DiscoTextSkeleton />
  </>
)

export default Relay.withSkeleton<TestIDProps>({
  component: ProfileSettingsDetailsTabNotes,
  skeleton: ProfileSettingsDetailsTabNotesSkeleton,
})
