import Badge from "@/admin/experiences/badges/Badge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import Format from "@/core/format/format"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import { usePricingDisplayValue } from "@/pricing/pricingUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { SearchHitType } from "@/search/search-hits/SearchHit"
import SearchHitTemplate, {
  SearchHitTemplateSkeleton,
} from "@/search/search-hits/SearchHitTemplate"
import {
  ProductSearchHitQuery,
  ProductType,
} from "@/search/search-hits/kinds/__generated__/ProductSearchHitQuery.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type ProductHit = {
  slug: string
  name: string
  description: string | null
  type: ProductType
}

interface ProductSearchHitProps extends TestIDProps {
  hit: SearchHitType
}

function ProductSearchHit({ hit }: ProductSearchHitProps) {
  const classes = useStyles()
  const copyToClipboard = useCopyToClipboard()
  const history = useHistory()
  const activeOrganization = useActiveOrganization()

  const { product } = useLazyLoadQuery<ProductSearchHitQuery>(
    graphql`
      query ProductSearchHitQuery($id: ID!) {
        product: node(id: $id) {
          ... on Product {
            id
            name
            description
            type
            slug
            badge {
              ...BadgeFragment
            }
            cover
            registrationAvailability
            startDate
            endDate
            registrationPricing {
              basePrice
              currency
            }
            pricing {
              ...pricingUtils_usePricingDisplayValue
            }
          }
        }
      }
    `,
    {
      id: hit.id as GlobalID,
    }
  )

  const pricingValue = usePricingDisplayValue({
    pricingKey: product?.pricing,
    currency: activeOrganization?.currency,
    length: "full",
  })

  if (!product) return null

  return (
    <SearchHitTemplate
      hit={hit}
      icon={getCover()}
      name={product.name}
      details={renderDetails()}
      onClick={handleClick}
      onCopy={handleCopyLink}
    />
  )

  function getCover() {
    if (!product) return null
    switch (product.type) {
      case "course":
        return <Badge badgeKey={product.badge!} size={40} />
      default:
        return (
          <CoverPhoto
            coverPhoto={product.cover || defaultThumbnail}
            customClassName={classes.cover}
          />
        )
    }
  }

  function renderDetails() {
    switch (product?.type) {
      case "course":
        return `${renderDate()} • ${renderAvailability()} • ${renderPrice()}`
      case "membership_plan":
        return pricingValue
      default:
        return null
    }
  }

  function renderDate() {
    if (product?.startDate) return formatDateWithOptions()(new Date(product.startDate))
    return "On Demand"
  }

  function renderAvailability() {
    switch (product?.registrationAvailability) {
      case "public":
        return "Public Access"
      case "private":
        return "Private Access"
      case "hidden":
        return "Hidden Access"
      default:
        return "Private Access"
    }
  }

  function renderPrice() {
    if (!product?.registrationPricing) return "Free"
    const { basePrice, currency } = product.registrationPricing
    if (!basePrice) return "Free"
    return Format.asCurrency(basePrice, { currency })
  }

  function handleClick() {
    if (!product) return

    switch (product.type) {
      case "membership_plan":
        return history.push({
          pathname: generatePath(ROUTE_NAMES.ADMIN.PRODUCTS.MEMBERSHIPS.ROOT),
          search: setSearchParams<GlobalDrawerParams<"membershipPlan">>(location.search, {
            membershipPlanId: product.id!,
          }),
        })
      case "course":
      default:
        return history.push(
          generatePath(ROUTE_NAMES.PRODUCT.ROOT, {
            productSlug: product!.slug!,
          })
        )
    }
  }

  function handleCopyLink(e: React.MouseEvent<HTMLButtonElement>) {
    if (!product) return
    e.stopPropagation()

    let productLink: string
    switch (product.type) {
      case "membership_plan":
        productLink = `${window.location.origin}${generatePath(
          ROUTE_NAMES.ADMIN.PRODUCTS.MEMBERSHIPS.ROOT
        )}?membershipPlanId=${product.id}`
        break
      case "course":
      default:
        productLink = `${window.location.origin}${generatePath(ROUTE_NAMES.PRODUCT.ROOT, {
          productSlug: product.slug!,
        })}`
        break
    }

    copyToClipboard(productLink)
  }
}

const useStyles = makeUseStyles((theme) => ({
  cover: {
    borderRadius: theme.measure.borderRadius.default,
    width: "100%",
  },
}))

export const ProductSearchHitSkeleton: React.FC<ProductSearchHitProps> = () => {
  return <SearchHitTemplateSkeleton />
}

export default Relay.withSkeleton<ProductSearchHitProps>({
  component: ProductSearchHit,
  skeleton: ProductSearchHitSkeleton,
})
