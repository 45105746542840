import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconKinds, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { ReactNode } from "react"

interface Props extends TestIDProps {
  icon: Omit<ReactNode, "string"> | DiscoIconKinds
  content: ReactNode
}

function IconWithContent(props: Props) {
  const { icon, content, testid = "IconWithContent" } = props
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid={testid}>
      {typeof icon === "string" ? <DiscoIcon icon={icon as DiscoIconKinds} /> : icon}
      {typeof content === "string" ? (
        <DiscoText color={"text.secondary"}>{content}</DiscoText>
      ) : (
        content
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "grid",
    gap: theme.spacing(1),
    gridTemplateColumns: "24px 1fr",
    marginBlock: theme.spacing(0.75),
  },
}))

export default IconWithContent
