import Badge from "@/admin/experiences/badges/Badge"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { SearchHitType } from "@/search/search-hits/SearchHit"
import SearchHitTemplate, {
  SearchHitTemplateSkeleton,
} from "@/search/search-hits/SearchHitTemplate"
import { FeedSearchHitQuery } from "@/search/search-hits/kinds/__generated__/FeedSearchHitQuery.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type FeedHit = {
  id: string
  organization_id: string
}

interface FeedSearchHitProps extends TestIDProps {
  hit: SearchHitType
}

function FeedSearchHit({ hit }: FeedSearchHitProps) {
  const classes = useStyles()
  const copyToClipboard = useCopyToClipboard()
  const history = useHistory()

  const { feed } = useLazyLoadQuery<FeedSearchHitQuery>(
    graphql`
      query FeedSearchHitQuery($id: ID!) {
        feed: node(id: $id) {
          ... on Feed {
            id
            name
            product {
              name
              slug
              badge {
                ...BadgeFragment
              }
            }
            app {
              badge {
                ...BadgeFragment
              }
              navSection {
                title
              }
            }
          }
        }
      }
    `,
    {
      id: hit.id as GlobalID,
    }
  )

  const product = feed?.product
  const navSection = feed?.app?.navSection
  if (!feed) return null

  return (
    <SearchHitTemplate
      hit={hit}
      icon={renderIcon()}
      name={feed.name}
      details={renderDetails()}
      onClick={handleClick}
      onCopy={handleCopyLink}
    />
  )

  function renderIcon() {
    if (!feed) return null

    return (
      <div className={classes.icon}>
        <Badge badgeKey={feed.app!.badge} size={40} className={classes.badge} />

        {product && (
          <div className={classes.subIconContainer}>
            <Badge badgeKey={product.badge} className={classes.subIcon} />
          </div>
        )}
      </div>
    )
  }

  function renderDetails() {
    return (
      <DiscoText
        variant={"body-sm"}
        color={"groovy.neutral.400"}
        truncateText={1}
        className={classes.subtitle}
        display={"inline"}
      >
        {"Feed"}
        {product?.name && (
          <>
            {" • "}
            <DiscoText
              component={"span"}
              variant={"body-sm"}
              truncateText={1}
              className={classes.product}
              color={"primary.main"}
            >
              {product.name}
            </DiscoText>
          </>
        )}
        {navSection?.title && (
          <>
            {" • "}
            <DiscoText
              component={"span"}
              variant={"body-sm"}
              truncateText={1}
              className={classes.product}
              color={"primary.main"}
            >
              {navSection.title}
            </DiscoText>
          </>
        )}
      </DiscoText>
    )
  }

  function getLink() {
    if (product) {
      return {
        pathname: generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
          productSlug: product.slug,
          feedId: feed.id!,
        }),
      }
    }

    return {
      pathname: generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, {
        feedId: feed!.id!,
      }),
    }
  }

  function handleClick() {
    const { pathname } = getLink()
    history.push({ pathname })
  }

  function handleCopyLink(e: React.MouseEvent<HTMLButtonElement>) {
    if (!feed?.id) return
    e.stopPropagation()
    const { pathname } = getLink()
    const feedLink = `${window.location.origin}${pathname}`
    copyToClipboard(feedLink)
  }
}

const useStyles = makeUseStyles((theme) => ({
  subtitle: {
    width: "100%",
  },
  product: {
    display: "contents",
  },
  icon: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  badge: {
    "& svg": {
      width: 32,
      height: 32,
    },
  },
  subIconContainer: {
    position: "absolute",
    bottom: -3,
    right: -3,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.medium,
  },
  subIcon: {
    borderRadius: theme.measure.borderRadius.small,
    height: 15,
    width: 15,
  },
}))

export const FeedSearchHitSkeleton: React.FC<FeedSearchHitProps> = () => {
  return <SearchHitTemplateSkeleton />
}

export default Relay.withSkeleton<FeedSearchHitProps>({
  component: FeedSearchHit,
  skeleton: FeedSearchHitSkeleton,
})
