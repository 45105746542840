/**
 * @generated SignedSource<<a7231a5d619e76a523955f7bda69df1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsEventRegistrationTableRowFragment$data = {
  readonly occurrence: {
    readonly id: string;
    readonly name: string | null;
    readonly datetimeRange: ReadonlyArray<string>;
    readonly didViewerAttend: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"OccurrenceWithDetailsFragment">;
  };
  readonly " $fragmentType": "ProfileSettingsEventRegistrationTableRowFragment";
};
export type ProfileSettingsEventRegistrationTableRowFragment$key = {
  readonly " $data"?: ProfileSettingsEventRegistrationTableRowFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsEventRegistrationTableRowFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsEventRegistrationTableRowFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Occurrence",
      "kind": "LinkedField",
      "name": "occurrence",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "datetimeRange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "didViewerAttend",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OccurrenceWithDetailsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OccurrenceRSVP",
  "abstractKey": null
};

(node as any).hash = "8cd9ab5996d8babd174e3369742cbdf8";

export default node;
