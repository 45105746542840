import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import { NotificationFamilyListItemFragment$key } from "@/user/settings/subtabs/notifications/family/__generated__/NotificationFamilyListItemFragment.graphql"
import NotificationKindListItem, {
  NotificationKindListItemSkeleton,
} from "@/user/settings/subtabs/notifications/family/kind/NotificationKindListItem"
import { DiscoDivider, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  familyKey: NotificationFamilyListItemFragment$key
  showDivider?: boolean
}

const NotificationFamilyListItem = ({ familyKey, showDivider = true }: Props) => {
  const classes = useStyles()

  const family = useFragment<NotificationFamilyListItemFragment$key>(
    graphql`
      fragment NotificationFamilyListItemFragment on NotificationFamily
      @argumentDefinitions(organizationId: { type: "ID!" }) {
        id
        name
        notificationKinds {
          edges {
            node {
              id
              channels
              ...NotificationKindListItemFragment
                @arguments(organizationId: $organizationId)
            }
          }
        }
      }
    `,
    familyKey
  )

  // Only display the notification kinds that has the "email" channel
  const notificationKinds = Relay.connectionToArray(family.notificationKinds).filter(
    (nk) => nk.channels.includes("email")
  )

  if (!notificationKinds.length) return null
  return (
    <>
      <div className={classes.family}>
        <DiscoText
          variant={"body-xs-500-uppercase"}
          marginBottom={0.5}
          color={"text.secondary"}
        >
          {family.name}
        </DiscoText>

        {notificationKinds.map((kind) => (
          <NotificationKindListItem key={kind.id} kindKey={kind} />
        ))}
      </div>
      {showDivider && <DiscoDivider marginTop={1} marginBottom={3} />}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  family: {
    paddingBottom: theme.spacing(2),
  },
}))

export const NotificationFamilyListItemSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.family}>
      <DiscoTextSkeleton variant={"body-xs-500-uppercase"} marginBottom={1} width={150} />

      <NotificationKindListItemSkeleton />
      <NotificationKindListItemSkeleton />
      <NotificationKindListItemSkeleton />
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: NotificationFamilyListItem,
  skeleton: NotificationFamilyListItemSkeleton,
})
