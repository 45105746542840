import { displaySuccessToast } from "@components/toast/ToastProvider"
import { useCallback } from "react"

function computeScrollAmountToMakeChildVisible(parent: HTMLElement, child: HTMLElement) {
  const { clientHeight: parentHeight, scrollTop: parentScrollTop } = parent
  const { clientHeight: childHeight, offsetTop: childOffsetTop } = child
  let visibilityBoundary = 0
  let scrollSize = 0

  visibilityBoundary = parentHeight - (childOffsetTop - parentScrollTop)

  if (visibilityBoundary < childHeight) {
    scrollSize = childHeight - visibilityBoundary
  }

  if (visibilityBoundary > parentHeight) {
    scrollSize = parentHeight - visibilityBoundary
  }

  return scrollSize
}

function copyToClipboard(textToCopy: string) {
  try {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy)
    } else {
      const dummpyClipboardElement = document.createElement("input")

      document.body.appendChild(dummpyClipboardElement)
      dummpyClipboardElement.value = textToCopy
      dummpyClipboardElement.select()
      document.execCommand("copy")
      document.body.removeChild(dummpyClipboardElement)
    }
  } catch (error) {
    console.error(error)
  }
}

function useCopyToClipboard(): (textToCopy: string) => void {
  const copyCallback = useCallback((text: string) => {
    copyToClipboard(text)
    displaySuccessToast({
      message: "Copied to clipboard!",
      testid: "CopyToClipboard.success-message",
    })
  }, [])

  return copyCallback
}

/** Check if the current ref can scroll to the right or left */
function hasHorizontalScrolls(componentRef: React.MutableRefObject<HTMLElement | null>) {
  const el = componentRef?.current

  if (!el) return { rightScroll: false, leftScroll: false }

  const hasHorizontalScroll = el.scrollWidth > el.clientWidth
  const isAtRight = Math.ceil(el.scrollLeft) === el.scrollWidth - el.clientWidth
  const isAtLeft = el.scrollLeft === 0

  return {
    rightScroll: hasHorizontalScroll && !isAtRight,
    leftScroll: hasHorizontalScroll && !isAtLeft,
  }
}

function getScrollParent(element: HTMLElement | null): HTMLElement | null {
  if (element == null) {
    return null
  }

  const { overflowY } = window.getComputedStyle(element)
  const isScrollable = overflowY !== "visible" && overflowY !== "hidden"

  if (isScrollable && element.scrollHeight > element.clientHeight) {
    return element
  }

  return getScrollParent(element.parentElement)
}

function isHTMLElement(x: unknown): x is HTMLElement {
  return x instanceof HTMLElement || x instanceof SVGElement
}

export {
  computeScrollAmountToMakeChildVisible,
  getScrollParent,
  hasHorizontalScrolls,
  isHTMLElement,
  useCopyToClipboard,
}
