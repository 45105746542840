import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import {
  NotificationChannel,
  NotificationKindListItemFragment$key,
} from "@/user/settings/subtabs/notifications/family/kind/__generated__/NotificationKindListItemFragment.graphql"
import { NotificationKindListItemMutation } from "@/user/settings/subtabs/notifications/family/kind/__generated__/NotificationKindListItemMutation.graphql"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoSwitch, DiscoSwitchSkeleton, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  kindKey: NotificationKindListItemFragment$key
}

const NotificationKindListItem = ({ kindKey }: Props) => {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  const kind = useFragment<NotificationKindListItemFragment$key>(
    graphql`
      fragment NotificationKindListItemFragment on NotificationKind
      @argumentDefinitions(organizationId: { type: "ID!" }) {
        id
        name
        description
        slug
        channels
        viewerOptOut(organizationId: $organizationId) {
          id
          channels {
            channel
            optedOut
          }
        }
      }
    `,
    kindKey
  )

  // Get the opted out channels for the viewer for this kind
  const optedOutChannels =
    kind.viewerOptOut?.channels
      .filter((channel) => channel.optedOut === true)
      .map((c) => c.channel) || []
  // Update user opt outs mutation, triggered when toggled
  const form = useFormStore<NotificationKindListItemMutation>(
    graphql`
      mutation NotificationKindListItemMutation($input: UpdateNotificationOptOutsInput!) {
        response: updateNotificationOptOuts(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      notificationKindSlug: kind.slug,
      channels: optedOutChannels,
      organizationId: activeOrganization.id,
      productId: null,
    }
  )
  if (kind.channels.length === 1 && kind.channels[0] === "in_app") {
    // for now, hide notification kinds that are only for in app notifications, as we don't support opt-out for that channel kind yet
    return null
  }

  return (
    <div className={classes.row}>
      <DiscoSwitch
        multipleSwitches={kind.channels
          // Only display the `email` channel toggles for now
          .filter((channel) => channel === "email")
          .map((channel) => {
            return {
              checked: !form.state.channels.includes(channel),
              onChange: () => handleNotificationOptOut(channel),
              testid: `NotificationOptOutSwitch.${kind.slug}.${
                form.state.channels.includes(channel) ? "unchecked" : "checked"
              }`,
              disabled: form.isSubmitting,
            }
          })}
        className={classes.settingsCheckbox}
        classes={{ root: classes.checkboxContainer }}
        label={
          <div className={classes.text}>
            <DiscoText variant={"body-md-600"} marginTop={1} marginBottom={0.5}>
              {kind.name}
            </DiscoText>
            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {kind.description}
            </DiscoText>
          </div>
        }
        disabled={form.isSubmitting}
      />
    </div>
  )

  async function handleNotificationOptOut(channel: NotificationChannel) {
    // Each channel can be toggled independently
    if (form.state.channels.includes(channel)) {
      form.state.channels.remove(channel)
    } else {
      form.state.channels.push(channel)
    }
    const { didSave } = await form.submit(form.state)
    if (!didSave) return

    displaySuccessToast({
      message: "Preferences updated!",
      testid: "NotificationKindOptOutSwitch.success-toast",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0.5, 0),
  },
  settingsCheckbox: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  checkboxContainer: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
  },
  text: {
    display: "block",
    color: theme.palette.text.primary,
  },
  switchSkeleton: {
    justifyContent: "flex-end",
  },
}))

export const NotificationKindListItemSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div className={classes.text}>
        <DiscoTextSkeleton
          variant={"body-md-600"}
          marginTop={1}
          marginBottom={0.5}
          width={200}
        />
        <DiscoTextSkeleton variant={"body-sm"} width={350} />
      </div>

      <div className={classes.switchSkeleton}>
        <DiscoSwitchSkeleton />
      </div>
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(NotificationKindListItem),
  skeleton: NotificationKindListItemSkeleton,
})
