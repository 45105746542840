import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import ProfileSettingsPrivacyChip from "@/user/settings/subtabs/profile/ProfileSettingsPrivacyChip"
import { UpdateUserProfileFormState } from "@/user/settings/subtabs/profile/ProfileSettingsUtils"
import { ProfileSettingsCustomFormFieldsFragment$key } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsCustomFormFieldsFragment.graphql"
import WebFormAnswer from "@/web-form/filler/WebFormAnswer"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoSection, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  form: FormStore<UpdateUserProfileFormState>
  organizationKey: ProfileSettingsCustomFormFieldsFragment$key
}

function ProfileSettingsCustomFormFields(props: Props) {
  const { form, organizationKey } = props
  const classes = useStyles()

  const organization = useFragment<ProfileSettingsCustomFormFieldsFragment$key>(
    graphql`
      fragment ProfileSettingsCustomFormFieldsFragment on Organization {
        customFields: profileFields(status: visible, isDefault: false) {
          edges {
            node {
              id
              title
              isPrivate
              webFormQuestionId
            }
          }
        }
        profileWebForm {
          currentRevision {
            questions {
              edges {
                node {
                  id
                  type
                  richEditorBody
                  options {
                    id
                    label
                  }
                }
              }
            }
          }
        }
      }
    `,
    organizationKey
  )
  const profileFields = Relay.connectionToArray(organization.customFields)
  const questions = Relay.connectionToArray(
    organization.profileWebForm?.currentRevision.questions
  )

  if (!profileFields.length) return null

  return (
    <DiscoSection
      margin={0}
      marginTop={2}
      padding={2}
      testid={"ProfileSettingsCustomFormFields"}
      groovyDepths={"insideCard"}
    >
      {profileFields.map((pf, i) => {
        const question = questions.find((q) => q.id === pf.webFormQuestionId)!
        const answerIndex = form.state.webFormSubmission!.answers.findIndex(
          (a) => a.webFormQuestionId === question.id
        )
        return (
          <div key={pf.id} className={classes.field}>
            <div className={classes.title}>
              <DiscoText
                variant={"body-sm-600"}
                testid={`ProfileSettingsCustomFormFields.${i}.title`}
              >
                {pf.title}
              </DiscoText>
              <ProfileSettingsPrivacyChip
                isPrivate={pf.isPrivate}
                testid={`ProfileSettingsCustomFormFields.${i}.privacy`}
              />
            </div>
            <DiscoEditor
              defaultValue={question.richEditorBody}
              readOnly
              disableGutter
              testid={`ProfileSettingsCustomFormFields.${i}.question`}
            />
            {question.type === "multiple_select" && (
              <DiscoText
                variant={"body-sm"}
                color={"groovy.neutral.400"}
                className={classes.selectAllThatApplyText}
                testid={`ProfileSettingsCustomFormFields.${i}.select-all-that-apply`}
              >
                {"Select all that apply"}
              </DiscoText>
            )}
            <WebFormAnswer
              question={question}
              form={form}
              answerIndex={answerIndex}
              testid={`ProfileSettingsCustomFormFields.${i}.answer`}
              variant={"compact"}
            />
          </div>
        )
      })}
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  field: {
    "&:not(:first-child)": {
      marginTop: theme.spacing(2.5),
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: "-2px",
  },
  selectAllThatApplyText: {
    marginBottom: theme.spacing(1),
  },
}))

export default observer(ProfileSettingsCustomFormFields)
