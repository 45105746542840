import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import ContentThumbnail from "@/content/detail/ContentThumbnail"
import ContentUtils from "@/content/util/contentUtils"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { ContentUsageSearchHitQuery } from "@/search/search-hits/kinds/__generated__/ContentUsageSearchHitQuery.graphql"
import { SearchHitType } from "@/search/search-hits/SearchHit"
import SearchHitTemplate, {
  SearchHitTemplateSkeleton,
} from "@/search/search-hits/SearchHitTemplate"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon } from "@disco-ui"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { useLazyLoadQuery } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type ContentUsageHit = {
  id: string
  organization_id: string
  content: {
    name: string
    description: string | null
    richEditorContentDescription: string | null
  }
}

interface ContentUsageSearchHitProps extends TestIDProps {
  hit: SearchHitType
}

function ContentUsageSearchHit({ hit }: ContentUsageSearchHitProps) {
  const classes = useStyles()
  const copyToClipboard = useCopyToClipboard()
  const history = useHistory()

  const { contentUsage } = useLazyLoadQuery<ContentUsageSearchHitQuery>(
    graphql`
      query ContentUsageSearchHitQuery($id: ID!) {
        contentUsage: node(id: $id) {
          ... on ContentUsage {
            id
            dueAt
            entity
            isLocked
            viewerPermissions
            content {
              id
              type
              label
              name
              creationDatetime
              ...ContentThumbnail_ContentFragment
            }
            product {
              name
              registrationAvailability
              slug
              badge {
                ...BadgeFragment
              }
            }
            collection {
              viewerPermissions
            }
            productApp {
              kind
              customAppTitle
              badge {
                ...BadgeFragment
              }
            }
            module {
              name
              usages {
                edges {
                  node {
                    id
                    entity
                    viewerPermissions
                    collection {
                      viewerPermissions
                    }
                  }
                }
              }
            }
            ...ContentUsageUtils_useContentUsagePathContentUsageFragment
            ...ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment
          }
        }
      }
    `,
    {
      id: hit.id as GlobalID,
    }
  )

  const content = contentUsage?.content
  const contentLabel = ContentUtils.useContentLabel({
    content: { type: content?.type, label: content?.label || "Content" },
    entity: contentUsage?.entity,
  })
  const pathname = ContentUsageUtils.useContentUsagePath(contentUsage!)
  const locationLabel = ContentUsageUtils.useContentUsageLocationLabel(contentUsage)

  if (!contentUsage) return null
  if (!content) return null
  if (!canView()) return null

  return (
    <SearchHitTemplate
      hit={hit}
      icon={
        <div className={classes.thumbnailContainer}>
          <ContentThumbnail contentKey={content} />
        </div>
      }
      name={content.name}
      nameAdornment={contentUsage.isLocked && <DiscoIcon icon={"lock"} />}
      details={renderDetails()}
      onClick={handleClick}
      onCopy={handleCopyLink}
    />
  )

  function renderDetails() {
    const label = contentLabel

    // Community Folders just specify the product app
    if (contentUsage?.content?.type === "module" && !contentUsage.product)
      return locationLabel
    if (locationLabel) return label.concat(` • ${locationLabel}`)
    return label
  }

  function handleClick() {
    if (!contentUsage?.id) return

    // If a folder or module, scroll the content usage into view
    if (contentUsage.entity === "collection" || contentUsage.content?.type === "module") {
      history.push({
        ...location,
        pathname,
        hash: contentUsage.id,
      })
    }

    // Otherwise, just navigate to the content usage
    else {
      history.push({
        ...location,
        pathname,
        search: setSearchParams(location.search, {
          contentId: undefined,
          u: Relay.fromGlobalId(contentUsage.id).id,
        }),
      })
    }
  }

  function handleCopyLink(e: React.MouseEvent<HTMLButtonElement>) {
    if (!contentUsage?.id) return
    e.stopPropagation()

    let contentUsageLink = `${window.location.origin}${pathname}?u=${
      Relay.fromGlobalId(contentUsage.id).id
    }`

    if (contentUsage.entity === "collection" || contentUsage.content?.type === "module") {
      contentUsageLink = `${window.location.origin}${pathname}#${contentUsage.id}`
    }

    copyToClipboard(contentUsageLink)
  }

  function canView() {
    if (!contentUsage) return false

    const sourceUsage = contentUsage.module
      ? Relay.connectionToArray(contentUsage.module.usages)[0]
      : contentUsage

    if (sourceUsage.entity === "collection") {
      return sourceUsage?.collection?.viewerPermissions.includes("collections.read")
    }

    return sourceUsage.viewerPermissions?.includes("content.read")
  }
}

const useStyles = makeUseStyles((theme) => ({
  thumbnailContainer: {
    position: "relative",
    minWidth: "91px",
    width: "91px",
    height: "46px",
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.medium,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& svg path": {
      color: theme.palette.text.primary,
    },
  },
}))

export const ContentUsageSearchHitSkeleton: React.FC<ContentUsageSearchHitProps> = () => {
  return <SearchHitTemplateSkeleton />
}

export default Relay.withSkeleton<ContentUsageSearchHitProps>({
  component: ContentUsageSearchHit,
  skeleton: ContentUsageSearchHitSkeleton,
})
