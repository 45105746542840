/**
 * @generated SignedSource<<f6bd30ee159a922d3712f6177529bc27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDropdownItemPrivateFragment$data = {
  readonly email: string | null;
  readonly " $fragmentType": "UserDropdownItemPrivateFragment";
};
export type UserDropdownItemPrivateFragment$key = {
  readonly " $data"?: UserDropdownItemPrivateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemPrivateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDropdownItemPrivateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "PrivateUser",
  "abstractKey": "__isPrivateUser"
};

(node as any).hash = "7991025fd087d285c0ef177633d1a742";

export default node;
