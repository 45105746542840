import CalendarIcon from "@/core/ui/images/empty-state/calendar.svg"
import Relay from "@/relay/relayUtils"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import ProfileSettingsEventRegistrationTableRow from "@/user/settings/subtabs/registrations/table/ProfileSettingsEventRegistrationTableRow"
import { ProfileSettingsEventRegistrationTablePaginationQuery } from "@/user/settings/subtabs/registrations/table/__generated__/ProfileSettingsEventRegistrationTablePaginationQuery.graphql"
import { ProfileSettingsEventRegistrationTableQuery } from "@/user/settings/subtabs/registrations/table/__generated__/ProfileSettingsEventRegistrationTableQuery.graphql"
import { ProfileSettingsEventRegistrationTable_PaginationFragment$key } from "@/user/settings/subtabs/registrations/table/__generated__/ProfileSettingsEventRegistrationTable_PaginationFragment.graphql"
import { DiscoEmptyState, DiscoTable, DiscoTableSkeletonWithHeader } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { graphql } from "relay-runtime"

const EVENTS_PER_PAGE = 5

type Props = TestIDProps

function ProfileSettingsEventRegistrationTable(props: Props) {
  const { testid = "ProfileSettingsEventRegistrationTable" } = props
  const [activePage, setActivePage] = useState(1)
  const { profile } = useMemberProfileContext()!
  const isMobile = useIsMobile()

  const { organizationMembership } =
    useLazyLoadQuery<ProfileSettingsEventRegistrationTableQuery>(
      graphql`
        query ProfileSettingsEventRegistrationTableQuery($id: ID!) {
          organizationMembership: node(id: $id) {
            ... on OrganizationMembership {
              ...ProfileSettingsEventRegistrationTable_PaginationFragment
            }
          }
        }
      `,
      { id: profile.organizationMembership?.id || "" },
      { fetchPolicy: "network-only" }
    )

  const { data, refetch } = usePaginationFragment<
    ProfileSettingsEventRegistrationTablePaginationQuery,
    ProfileSettingsEventRegistrationTable_PaginationFragment$key
  >(
    graphql`
      fragment ProfileSettingsEventRegistrationTable_PaginationFragment on OrganizationMembership
      @refetchable(queryName: "ProfileSettingsEventRegistrationTablePaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
      ) {
        id
        occurrenceRSVPs(first: $first, after: $after, last: $last, before: $before)
          @connection(key: "ProfileSettingsEventRegistrationTable_occurrenceRSVPs") {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...ProfileSettingsEventRegistrationTableRowFragment
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organizationMembership
  )

  if (!data) return null
  const occurrenceRSVPs = Relay.connectionToArray(data.occurrenceRSVPs)

  return (
    <DiscoTable
      sectionProps={{
        padding: 1.5,
        groovyDepths: "insideCard",
      }}
      emptyState={
        <DiscoEmptyState
          testid={`${testid}.EmptyState`}
          icon={<CalendarIcon width={139} height={152} />}
          subtitle={"You haven't registered for any Events."}
        />
      }
      activePage={activePage}
      setActivePage={setActivePage}
      rowsPerPage={EVENTS_PER_PAGE}
      testid={"ProfileSettingsEventRegistrationTable"}
      onPaginate={refetch}
      rows={occurrenceRSVPs.map((rsvp) => (
        <ProfileSettingsEventRegistrationTableRow
          key={rsvp.id}
          occurrenceRSVPKey={rsvp}
        />
      ))}
      connection={{
        cursorsList: data.occurrenceRSVPs?.edges.map((e) => e.cursor),
        totalCount: data.occurrenceRSVPs?.totalCount || 0,
        pageInfo: {
          endCursor: data.occurrenceRSVPs?.pageInfo.endCursor || null,
          startCursor: data.occurrenceRSVPs?.pageInfo.startCursor || null,
        },
      }}
      header={
        isMobile ? undefined : [{ value: "Name", width: "65%" }, { value: "Attended" }]
      }
    />
  )
}

export function ProfileSettingsEventRegistrationTableSkeleton() {
  const isMobile = useIsMobile()

  return (
    <DiscoTableSkeletonWithHeader
      sectionProps={{
        padding: 1.5,
        groovyDepths: "insideCard",
      }}
      header={
        isMobile ? undefined : [{ value: "Name", width: "65%" }, { value: "Attended" }]
      }
      rows={5}
    />
  )
}

export default Relay.withSkeleton({
  component: ProfileSettingsEventRegistrationTable,
  skeleton: ProfileSettingsEventRegistrationTableSkeleton,
})
