import OrganizationRoleTag from "@/role/OrganizationRoleTag"
import ProductRoleTag from "@/role/ProductRoleTag"
import { ProfileNameWithTagFragment$key } from "@/user/common/name-with-tag/__generated__/ProfileNameWithTagFragment.graphql"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  userKey: ProfileNameWithTagFragment$key
  className?: string
}

function ProfileNameWithTag(props: Props) {
  const { userKey, className, testid = "ProfileNameWithTag" } = props
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const { profile } = useMemberProfileContext()!

  const user = useFragment<ProfileNameWithTagFragment$key>(
    graphql`
      fragment ProfileNameWithTagFragment on User {
        fullName
      }
    `,
    userKey
  )

  const orgRole = profile.organizationMembership?.role
  const productRole = profile.productMembership?.role

  return (
    <div className={classNames(classes.root, className)} data-testid={testid}>
      <DiscoText
        variant={isMobile ? "body-lg-600" : "heading-md"}
        testid={`${testid}.full-name`}
        // Hide name in FS recordings
        className={"fs-mask"}
      >
        {user.fullName}
      </DiscoText>
      <div className={classes.tagContainer}>
        {/* Don't show organization "member" tag when in a product */}
        {orgRole && (!productRole || orgRole !== "member") && (
          <OrganizationRoleTag organizationRole={orgRole} />
        )}
        {productRole && <ProductRoleTag productRole={productRole} />}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "& > :first-child": {
      width: "100%",
    },
  },
  tagContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
}))

export default ProfileNameWithTag
