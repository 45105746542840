import { BasicUser } from "@/user/api/usersApiModels"

function getUserFullName(
  user: Pick<BasicUser, "first_name" | "last_name">,
  defaultName = "Registered User"
): string {
  return user.first_name
    ? `${user.first_name || ""} ${user.last_name || ""}`
    : defaultName
}

export { getUserFullName }
