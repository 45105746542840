/**
 * @generated SignedSource<<79b856e31f47d462f974d7f1f17a7313>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsProfileTabFragment$data = {
  readonly id: string;
  readonly website: string;
  readonly timezone: string;
  readonly bio: string;
  readonly lastName: string | null;
  readonly firstName: string | null;
  readonly avatar: string;
  readonly cover: string | null;
  readonly organizationMembership: {
    readonly profileValues: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly profileField: {
            readonly webFormQuestionId: string | null;
          };
          readonly value: string;
          readonly selectedOptions: ReadonlyArray<string> | null;
          readonly webFormAnswer: {
            readonly body: string | null;
            readonly selectedOptions: ReadonlyArray<string> | null;
          } | null;
        };
      }>;
    };
    readonly organization: {
      readonly profileWebForm: {
        readonly currentRevision: {
          readonly id: string;
          readonly webFormId: string;
          readonly questions: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly type: WebFormQuestionType;
                readonly richEditorBody: string;
                readonly ordering: number;
                readonly isRequired: boolean;
                readonly options: ReadonlyArray<{
                  readonly id: string;
                  readonly label: string;
                }> | null;
              };
            }>;
          };
        };
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsProfileFormFieldsFragment" | "ProfileSettingsCustomFormFieldsFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsCustomFieldsFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsProfileBannerFragment" | "ProfileSettingsUserSectionFragment" | "ProfileNameWithTagFragment">;
  readonly " $fragmentType": "ProfileSettingsProfileTabFragment";
};
export type ProfileSettingsProfileTabFragment$key = {
  readonly " $data"?: ProfileSettingsProfileTabFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsProfileTabFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "selectedOptions",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsProfileTabFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "organizationMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileValueNodeConnection",
          "kind": "LinkedField",
          "name": "profileValues",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProfileValueNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProfileValue",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProfileField",
                      "kind": "LinkedField",
                      "name": "profileField",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "webFormQuestionId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormAnswer",
                      "kind": "LinkedField",
                      "name": "webFormAnswer",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "body",
                          "storageKey": null
                        },
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WebForm",
              "kind": "LinkedField",
              "name": "profileWebForm",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormRevision",
                  "kind": "LinkedField",
                  "name": "currentRevision",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "webFormId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormQuestionNodeConnection",
                      "kind": "LinkedField",
                      "name": "questions",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WebFormQuestionNodeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "WebFormQuestion",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "type",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "richEditorBody",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "ordering",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isRequired",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "WebFormQuestionOption",
                                  "kind": "LinkedField",
                                  "name": "options",
                                  "plural": true,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "label",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProfileSettingsProfileFormFieldsFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProfileSettingsCustomFormFieldsFragment"
            }
          ],
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "isPrivate",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "ProfileSettingsCustomFieldsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileSettingsProfileBannerFragment"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "ProfileSettingsUserSectionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileNameWithTagFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "93453a40f5e8914d1dfc6e4720ed7d0c";

export default node;
