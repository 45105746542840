import ROUTE_NAMES from "@/core/route/util/routeNames"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { FC } from "react"

interface Props {
  children: OverridableDiscoButtonChildren
}

const ConnectOutlookConnectionButton: FC<Props> = ({ children }) => {
  return (
    <OverridableDiscoButton
      testid={"ConnectOutlookConnectionButton"}
      onClick={openOutlookConnectionPage}
    >
      {children}
    </OverridableDiscoButton>
  )
}

export function openOutlookConnectionPage() {
  const win = window.open(ROUTE_NAMES.OUTLOOK_LANDING, "_blank")
  win?.focus()
}

export default ConnectOutlookConnectionButton
