import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import { UserNotesCreateFormMutation } from "@/user/notes/form/__generated__/UserNotesCreateFormMutation.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoButtonSkeleton, DiscoInputSkeleton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql } from "react-relay"
import { RecordSourceProxy } from "relay-runtime"

interface UserNotesCreateForm extends TestIDProps {
  organizationMembershipId: GlobalID
  notesConnectionId: string
}

function UserNotesCreateForm(props: UserNotesCreateForm) {
  const { organizationMembershipId, notesConnectionId, testid } = props
  const theme = useTheme()
  const classes = useStyles()

  const form = useFormStore<UserNotesCreateFormMutation>(
    graphql`
      mutation UserNotesCreateFormMutation(
        $input: CreateUserNoteInput!
        $connections: [ID!]!
      ) {
        response: createUserNote(input: $input) {
          node @prependNode(connections: $connections, edgeTypeName: "UserNoteNodeEdge") {
            id
            ...UserNotesItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationMembershipId,
      body: "",
    }
  )

  return (
    <>
      <DiscoEditor
        backgroundColor={theme.palette.background.default}
        onChange={handleRichEditorChange}
        placeholder={"Leave a note..."}
        testid={`${testid}.editor`}
        minHeight={115}
        config={"basic_formatting"}
      />
      <div className={classes.submitButtonContainer}>
        <DiscoButton
          testid={`${testid}.submit-button`}
          disabled={form.isSubmitting || EditorUtils.isEmpty(form.state.body)}
          className={classes.submitButton}
          shouldDisplaySpinner={form.isSubmitting}
          onClick={handleSubmit}
        >
          {"Add Note"}
        </DiscoButton>
      </div>
    </>
  )

  function handleRichEditorChange(v: string) {
    form.state.body = v
  }

  async function handleSubmit() {
    if (EditorUtils.isEmpty(form.state.body)) {
      displayErrorToast("You must enter a note.")
      return
    }

    const { didSave } = await form.submit(form.state, {
      connections: [notesConnectionId],
      updater,
    })
    if (!didSave) return

    displaySuccessToast({
      message: "Note added",
      testid: `${testid}.UserNotesCreateForm.success-toast`,
    })
  }

  function updater(store: RecordSourceProxy) {
    const notesConnection = store.get(notesConnectionId)
    const totalCount = notesConnection?.getValue("totalCount")
    notesConnection?.setValue(((totalCount as number) ?? 0) + 1, "totalCount")
  }
}

const useStyles = makeUseStyles((theme) => ({
  submitButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  submitButton: {
    width: "120px",
    marginTop: theme.spacing(1),
  },
}))

export const UserNotesCreateFormSkeleton = () => {
  const classes = useStyles()
  return (
    <>
      <DiscoInputSkeleton height={150} />
      <div className={classes.submitButtonContainer}>
        <DiscoButtonSkeleton className={classes.submitButton} />
      </div>
    </>
  )
}

export default observer(UserNotesCreateForm)
