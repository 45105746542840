import useStartDirectMessage from "@/chat/hooks/useStartDirectMessage"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import FormStore from "@/core/form/store/FormStore"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import ProfileNameWithTag from "@/user/common/name-with-tag/ProfileNameWithTag"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import { ProfileSettingsProfileBannerFragment$key } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsProfileBannerFragment.graphql"
import {
  ProfileSettingsProfileTabMutation,
  UpdateUserProfileInput,
} from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsProfileTabMutation.graphql"
import defaultUserCoverPhoto from "@assets/images/covers/default-user-cover-photo.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import MediaUploadButton from "@components/media/upload/MediaUploadButton"
import {
  DiscoButton,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoSection,
  DiscoTextButton,
  DiscoTextSkeleton,
} from "@disco-ui"
import { IconButton, Theme, useMediaQuery } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  form: FormStore<UpdateUserProfileInput, ProfileSettingsProfileTabMutation>
  userKey: ProfileSettingsProfileBannerFragment$key
}

function ProfileSettingsProfileBanner(props: Props) {
  const { form, userKey } = props
  const testid = "ProfileSettingsProfileBanner"
  const showOnHoverClasses = useShowOnHoverStyles()

  const drawer = useGlobalDrawer("profileSettings")
  const { isSelfViewing, profile } = useMemberProfileContext()!
  const isMobile = useIsMobile()
  const avatarSize = isMobile ? 96 : 120
  const classes = useStyles()

  const [startDirectMessage, isStartingDM] = useStartDirectMessage()
  const activeOrganization = useActiveOrganization()!

  const user = useFragment<ProfileSettingsProfileBannerFragment$key>(
    graphql`
      fragment ProfileSettingsProfileBannerFragment on User {
        id
        cover
        ...ProfileAvatarFragment
        ...ProfileNameWithTagFragment
      }
    `,
    userKey
  )

  return (
    <DiscoSection marginTop={1} marginBottom={2} padding={0} margin={0}>
      <DiscoFormControl
        className={classNames(classes.coverPhotoControl, showOnHoverClasses.hoverable)}
      >
        <CoverPhoto
          // Display a default cover photo until we can add a default cover photo when creating the user in Django
          coverPhoto={
            (drawer.params.editProfile === "1" ? form.state.cover! : user.cover) ||
            defaultUserCoverPhoto
          }
          customClassName={classes.coverPhoto}
        />
        {/** Add a action buttons overlay when editing */}
        {drawer.params.editProfile === "1" && (
          <div
            className={classNames(
              classes.coverPhotoActionButtonsOverlay,
              showOnHoverClasses.showable
            )}
          >
            {form.state.cover &&
              (isMobile ? (
                <DiscoIconButton
                  className={classes.actionIconButton}
                  onClick={() => (form.state.cover = null)}
                >
                  <DiscoIcon icon={"trash"} />
                </DiscoIconButton>
              ) : (
                <DiscoButton
                  testid={`${testid}.remove`}
                  color={"grey"}
                  variant={"outlined"}
                  leftIcon={"trash"}
                  onClick={() => (form.state.cover = null)}
                >
                  {"Remove"}
                </DiscoButton>
              ))}
            {isMobile ? (
              <MediaUploadButton
                testid={`${testid}.change`}
                accept={IMAGE_FILE_TYPES}
                onSuccess={(result) => (form.state.cover = result.url)}
                color={"transparent"}
                className={classes.transparentButton}
                cropperProps={{
                  stencilProps: { aspectRatio: ASPECT_RATIOS.BANNER },
                }}
                includeOrganizationIdOnAsset={false}
                includeInMediaLibrary={false}
              >
                <DiscoIconButton className={classes.actionIconButton}>
                  <DiscoIcon icon={"pencil"} />
                </DiscoIconButton>
              </MediaUploadButton>
            ) : (
              <MediaUploadButton
                testid={`${testid}.change`}
                color={"grey"}
                variant={"outlined"}
                accept={IMAGE_FILE_TYPES}
                onSuccess={(result) => (form.state.cover = result.url)}
                leftIcon={"pencil"}
                cropperProps={{
                  stencilProps: { aspectRatio: ASPECT_RATIOS.BANNER },
                }}
                includeOrganizationIdOnAsset={false}
                includeInMediaLibrary={false}
              >
                {"Edit Cover Photo"}
              </MediaUploadButton>
            )}
          </div>
        )}
      </DiscoFormControl>

      <div className={classes.details}>
        <div className={classes.avatarWrapper}>
          <div className={classNames(classes.avatarArea, showOnHoverClasses.hoverable)}>
            {/** Add a action buttons overlay when editing */}
            {drawer.params.editProfile === "1" ? (
              <>
                <UserAvatar
                  testid={`${testid}-avatar`}
                  user={{
                    avatar: form.state.avatar || null,
                    first_name: form.state.firstName || null,
                    last_name: form.state.lastName || null,
                    id: user.id,
                  }}
                  size={avatarSize}
                  dropShadow
                />
                <div
                  className={classNames(
                    classes.avatarActionOverlay,
                    showOnHoverClasses.showable
                  )}
                >
                  <MediaUploadButton
                    testid={`${testid}.change`}
                    className={classes.transparentButton}
                    accept={IMAGE_FILE_TYPES}
                    color={"transparent"}
                    onSuccess={(result) => (form.state.avatar = result.url)}
                    cropperProps={{
                      stencilProps: { aspectRatio: ASPECT_RATIOS.SQUARE },
                    }}
                    includeOrganizationIdOnAsset={false}
                    includeInMediaLibrary={false}
                  >
                    <IconButton
                      className={classes.actionIconButton}
                      classes={{
                        root: classes.editAvatarButton,
                      }}
                    >
                      <DiscoIcon icon={"camera"} width={24} height={24} />
                    </IconButton>
                  </MediaUploadButton>
                </div>
              </>
            ) : (
              <ProfileAvatar userKey={user} size={avatarSize} dropShadow />
            )}
          </div>
        </div>
        <div className={classes.nameHeader}>
          {!isMobile && <ProfileNameWithTag userKey={user} className={classes.name} />}
          {renderActionButton()}
        </div>
        {drawer.params.editProfile === "1" && form.state.avatar && (
          <DiscoTextButton
            onClick={() => (form.state.avatar = "")}
            className={classes.removeAvatarButton}
          >
            {"Remove Photo"}
          </DiscoTextButton>
        )}
      </div>
    </DiscoSection>
  )

  function handleEdit() {
    drawer.setParams({ editProfile: "1" })
  }

  function renderActionButton() {
    if (
      !isSelfViewing &&
      drawer.params.editProfile !== "1" &&
      profile.organizationMembership &&
      activeOrganization.isDmEnabled
    ) {
      return (
        <DiscoButton
          data-testid={"ProfileSettingsProfileBanner.send-message-button"}
          shouldDisplaySpinner={isStartingDM}
          onClick={(e) => {
            e.stopPropagation()
            startDirectMessage([profile.organizationMembership!.id])
            drawer.close()
          }}
        >
          {"Send Message"}
        </DiscoButton>
      )
    }

    // Don't allow any action if the user is not editing or viewing their own profile
    if (!isSelfViewing || drawer.params.editProfile === "1") return null

    return (
      <DiscoButton
        onClick={handleEdit}
        leftIcon={"pencil"}
        testid={`${testid}.edit-button`}
      >
        {"Edit"}
      </DiscoButton>
    )
  }
}

export const ProfileSettingsProfileBannerSkeleton = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const avatarSize = isMobile ? 96 : 120
  const classes = useStyles()

  return (
    <DiscoSection padding={0}>
      <Skeleton width={"100%"} variant={"rect"} height={isMobile ? 94 : 164} />

      <div className={classes.details}>
        <div className={classes.avatarWrapper}>
          <div className={classes.avatarArea}>
            <Skeleton variant={"circle"} width={avatarSize} height={avatarSize} />
          </div>
        </div>
        <div className={classes.nameHeader}>
          <div className={classes.name}>
            {!isMobile && (
              <DiscoTextSkeleton marginTop={2} variant={"heading-md"} width={200} />
            )}
          </div>
        </div>
      </div>
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  coverPhoto: {
    // getting a makeUseStyles return type error without this assertion
    borderRadius: theme.measure.borderRadius.big!,
    backgroundColor: theme.palette.groovy.neutral[100],
    paddingTop: 0,
    // use 'cover' so that legacy images cropped to the wrong size will still work, images with the correct aspect ratio going forward should render without cropping
    backgroundSize: "cover",
    width: "100%",
    aspectRatio: ASPECT_RATIOS.BANNER,
  },
  coverPhotoControl: {
    position: "relative",
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
  coverPhotoActionButtonsOverlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    gap: theme.spacing(1.5),
    [theme.breakpoints.down("xs")]: {
      top: "auto",
      left: "auto",
      transform: "none",
      right: 10,
      bottom: 10,
    },
  },
  actionIconButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  details: {
    display: "grid",
    gridTemplateColumns: "168px 1fr",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "144px 1fr",
    },
  },
  nameHeader: {
    width: "100%",
    height: "45px",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  },
  name: {
    maxWidth: "330px",
  },
  avatarArea: {
    display: "grid",
    placeItems: "center",
    width: "100%",
    position: "absolute",
    bottom: "100%",
    transform: "translateY(43%)",
  },
  avatarWrapper: {
    position: "relative",
    height: "100%",
  },
  avatarActionOverlay: {
    position: "absolute",
  },
  editAvatarButton: {
    width: "40px",
    height: "40px",
    border: `1px solid ${theme.palette.groovy.neutral[100]}`,
    padding: 0,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  transparentButton: {
    padding: 0,
    backgroundColor: "transparent",
    height: "unset",
  },
  removeAvatarButton: {
    marginTop: theme.spacing(1),
  },
}))

export default observer(ProfileSettingsProfileBanner)
