import Relay from "@/relay/relayUtils"
import { ProfileSettingsProfileTabFragment$data } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsProfileTabFragment.graphql"
import { UpdateUserProfileInput as ReadonlyUpdateUserProfileInput } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsProfileTabMutation.graphql"
import {
  CreateWebFormSubmissionInput,
  WebFormFillerRevisionData,
  getWebFormSubmissionInput,
} from "@/web-form/utils/webFormFillerUtils"

export type UpdateUserProfileFormState = Omit<
  ReadonlyUpdateUserProfileInput,
  "webFormSubmission"
> & {
  webFormSubmission?: CreateWebFormSubmissionInput | null
}

type ProfileValuesConnection = NonNullable<
  ProfileSettingsProfileTabFragment$data["organizationMembership"]
>["profileValues"]

/**
 * Set up custom profile field web form submission data with pre-filled values if
 * they exist for this user
 */
export function initUpdateProfileWebFormSubmissionInput(
  profileWebFormRevision: WebFormFillerRevisionData,
  profileValuesConnection?: ProfileValuesConnection
) {
  // Prefill answers using the latest submission's answers
  const profileValues = Relay.connectionToArray(profileValuesConnection)
  const prefillAnswers = []
  for (const profileValue of profileValues) {
    if (!profileValue.profileField.webFormQuestionId) continue
    if (profileValue.webFormAnswer) {
      prefillAnswers.push({
        body: profileValue.webFormAnswer.body,
        selectedOptions: profileValue.webFormAnswer.selectedOptions?.slice(),
        // Use profileField's webFormQuestionId instead of profileValue's since a new
        // form revision may have been created since the last profile update
        webFormQuestionId: profileValue.profileField.webFormQuestionId,
      })
    } else {
      // Handle the special "Find me at" field which may not have a web form answer
      prefillAnswers.push({
        body: profileValue.value,
        webFormQuestionId: profileValue.profileField.webFormQuestionId,
      })
    }
  }

  return getWebFormSubmissionInput(profileWebFormRevision, prefillAnswers)
}
