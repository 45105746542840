import CommentUtils from "@/comments/utils/CommentUtils"
import { ProfileAvatarFragment$key } from "@/user/common/avatar/__generated__/ProfileAvatarFragment.graphql"
import {
  DEFAULT_USER_AVATAR_SIZE,
  USER_AVATAR_PLACEHOLDER_TEXT_CHARACTER_LIMIT,
} from "@/user/common/avatar/util/userAvatarConstants"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Avatar from "@components/avatar/Avatar"
import AvatarPlaceholder from "@components/avatar/placeholder/AvatarPlaceholder"
import { DiscoIcon } from "@disco-ui"
import { getInitialLettersOfWords } from "@utils/string/stringUtils"
import { TestIDProps } from "@utils/typeUtils"
import classnames from "classnames"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ProfileAvatarProps extends TestIDProps {
  /** This key won't exist for users that are no longer part of a product (ie. they left) */
  userKey: ProfileAvatarFragment$key | null
  size?: ProfileAvatarSize
  placeholderTextClassName?: string
  placeholderClassName?: string
  className?: string
  /** If true, apply a drop shadow on the component */
  dropShadow?: boolean
  badgeIcon?: React.ReactNode
  user?: {
    id?: string
    fullName?: string
    avatar?: string | null
  }
}

export type ProfileAvatarSize = 24 | 32 | 40 | 48 | 64 | 96 | 120

function ProfileAvatar({
  userKey,
  size = DEFAULT_USER_AVATAR_SIZE,
  placeholderTextClassName,
  placeholderClassName,
  testid = "ProfileAvatar",
  badgeIcon,
  dropShadow,
  className,
  user: propsUser,
}: ProfileAvatarProps) {
  const classes = useStyles()
  const user = useFragment<ProfileAvatarFragment$key>(
    graphql`
      fragment ProfileAvatarFragment on User {
        id
        fullName
        avatar
      }
    `,
    userKey
  )

  const {
    id,
    fullName = CommentUtils.getUnknownUserFullName(),
    avatar,
  } = user || propsUser || {}

  return (
    <div className={classes.avatarBadgeContainer}>
      <Avatar
        alt={fullName}
        src={avatar}
        className={classnames(className, "fs-mask")}
        size={size}
        dropShadow={dropShadow}
        placeholder={
          fullName ? (
            <AvatarPlaceholder
              testid={`${testid}.AvatarPlaceholder`}
              text={getInitialLettersOfWords(fullName).substring(
                0,
                USER_AVATAR_PLACEHOLDER_TEXT_CHARACTER_LIMIT
              )}
              uuid={id}
              size={size}
              textClassName={placeholderTextClassName}
              className={placeholderClassName}
              dropShadow={dropShadow}
            />
          ) : (
            <DiscoIcon icon={"user"} width={size} height={size} />
          )
        }
      />
      {badgeIcon && <div className={classes.badgeContainer}>{badgeIcon}</div>}
    </div>
  )
}

const useStyles = makeUseStyles(() => ({
  avatarBadgeContainer: {
    display: "flex",
    position: "relative",
  },
  badgeContainer: {
    position: "absolute",
    right: "-4px", // negative value here so it aligns outside of the bounding div
    bottom: "-10px", // negative value here so it aligns outside of the bounding div
  },
}))

export default ProfileAvatar
