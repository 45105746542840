import UserAvatar from "@/user/common/avatar/UserAvatar"
import { UserDropdownItemFragment$key } from "@/user/common/__generated__/UserDropdownItemFragment.graphql"
import { UserDropdownItemPrivateFragment$key } from "@/user/common/__generated__/UserDropdownItemPrivateFragment.graphql"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { graphql, useFragment } from "react-relay"

interface Props {
  testid: string
  userKey: UserDropdownItemFragment$key
  privateUserKey: UserDropdownItemPrivateFragment$key | null
  className?: string
}

function UserDropdownItem(props: Props) {
  const { testid, userKey, privateUserKey, className } = props

  const user = useFragment<UserDropdownItemFragment$key>(
    graphql`
      fragment UserDropdownItemFragment on User {
        id
        avatar
        fullName
        first_name: firstName
        last_name: lastName
      }
    `,
    userKey
  )

  const privateUser = useFragment<UserDropdownItemPrivateFragment$key>(
    graphql`
      fragment UserDropdownItemPrivateFragment on PrivateUser {
        email
      }
    `,
    privateUserKey
  )

  return (
    <DiscoDropdownItem
      className={className}
      testid={testid}
      icon={
        <UserAvatar
          testid={`${testid}.avatar`}
          user={user}
          size={privateUser ? 40 : 24}
        />
      }
      title={user.fullName}
      subtitle={privateUser?.email}
      component={"div"}
    />
  )
}

export default UserDropdownItem
