/**
 * @generated SignedSource<<e3f1e8714930b6d46ac7ee67322d7b18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileAvatarFragment$data = {
  readonly id: string;
  readonly fullName: string;
  readonly avatar: string;
  readonly " $fragmentType": "ProfileAvatarFragment";
};
export type ProfileAvatarFragment$key = {
  readonly " $data"?: ProfileAvatarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileAvatarFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d6fe2f5c9d0d13010e53014bc47d04ae";

export default node;
