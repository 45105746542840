import FilesIcon from "@/core/ui/images/empty-state/files.svg"
import Relay from "@/relay/relayUtils"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import { ProfileSettingsCertificateSectionFragment$key } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsCertificateSectionFragment.graphql"
import CertificateCard from "@/user/settings/subtabs/profile/certificates/CertificateCard"
import { DiscoEmptyState, DiscoSection, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { graphql, useFragment } from "react-relay"

function ProfileSettingsCertificateSection() {
  const { profile, isSelfViewing } = useMemberProfileContext()!

  const organizationMembership =
    useFragment<ProfileSettingsCertificateSectionFragment$key>(
      graphql`
        fragment ProfileSettingsCertificateSectionFragment on OrganizationMembership {
          userCertificates {
            edges {
              node {
                id
                ...CertificateCardFragment
              }
            }
          }
        }
      `,
      profile.organizationMembership
    )

  if (!organizationMembership) return null

  const userCertificates = Relay.connectionToArray(
    organizationMembership.userCertificates
  )
  const isAdminProfile =
    profile.productMembership?.role === "manager" ||
    profile.productMembership?.role === "instructor" ||
    profile.organizationMembership?.role === "owner" ||
    profile.organizationMembership?.role === "admin"

  // Hide the section if the user is an admin or no certificates
  if (!isSelfViewing && (isAdminProfile || !userCertificates.length)) {
    return null
  }

  return (
    <DiscoSection marginTop={4} padding={0} margin={0}>
      <DiscoText variant={"body-md-600"} marginBottom={1.5}>
        {"Achievements"}
      </DiscoText>
      {userCertificates.length > 0 ? (
        <Grid container spacing={3} wrap={"wrap"}>
          {userCertificates.map((userCertificate, i) => (
            <Grid key={userCertificate.id} xs={12} md={6}>
              <CertificateCard
                userCertificateKey={userCertificate}
                testid={`ProductCertificateTemplatesListItem-${i}`}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <DiscoSection
          radius={"big"}
          marginBottom={3}
          padding={0}
          groovyDepths={"insideCard"}
        >
          <DiscoEmptyState
            testid={"ProfileSettingsCertificateSection"}
            icon={<FilesIcon width={156} height={152} />}
            subtitle={"Certificates are used to recognize your achievements."}
          />
        </DiscoSection>
      )}
    </DiscoSection>
  )
}

export default ProfileSettingsCertificateSection
