import Relay from "@/relay/relayUtils"
import {
  CreateStripeSetupIntentInput,
  useHandleFuturePaymentMutation,
} from "@/stripe/util/hook/__generated__/useHandleFuturePaymentMutation.graphql"
import type { Stripe } from "@stripe/stripe-js"
import { useCallback } from "react"
import { graphql } from "relay-runtime"

// Creates a Stripe setup intent to charge a user off session
function useHandleFuturePayment() {
  const createStripeSetupIntent =
    Relay.useAsyncMutation<useHandleFuturePaymentMutation>(graphql`
      mutation useHandleFuturePaymentMutation($input: CreateStripeSetupIntentInput!) {
        createStripeSetupIntent(input: $input) {
          status
          clientSecret
          errors {
            message
          }
        }
      }
    `)

  const handleFuturePayment = useCallback(
    async (input: CreateStripeSetupIntentInput, stripeClient: Stripe) => {
      const res = await createStripeSetupIntent({ input })

      // Check for errors
      if (res.createStripeSetupIntent.errors) {
        throw new Error(res.createStripeSetupIntent.errors[0].message)
      }

      // Handle 3DS authentication if required
      if (res.createStripeSetupIntent.status !== "requires_action") return
      if (!res.createStripeSetupIntent.clientSecret)
        throw new Error("Missing clientSecret")

      const setupIntentResponse = await stripeClient.confirmCardSetup(
        res.createStripeSetupIntent.clientSecret,
        {
          payment_method: input.paymentMethodId,
        }
      )

      if (setupIntentResponse.error) throw new Error(setupIntentResponse.error.message)
    },
    [createStripeSetupIntent]
  )

  return handleFuturePayment
}

export default useHandleFuturePayment
