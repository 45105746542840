import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import Relay from "@/relay/relayUtils"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import {
  ProfileSettingsCustomFieldsFragment$key,
  WebFormQuestionType,
} from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsCustomFieldsFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ReadMoreButton from "@components/read-more-button/ReadMoreButton"
import { truncateTextMiddle } from "@utils/string/stringUtils"
import { DiscoLink, DiscoSection, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { DiscoTextWithLinks } from "@disco-ui/text/DiscoTextWithLinks"
import { Fragment } from "react"
import { graphql, useFragment } from "react-relay"

type Props = {
  organizationMembershipKey: ProfileSettingsCustomFieldsFragment$key
}

function ProfileSettingsCustomFields({ organizationMembershipKey }: Props) {
  const classes = useStyles()
  const { isSelfViewing } = useMemberProfileContext()!
  const activeOrganization = useActiveOrganization()
  const isAdmin = activeOrganization?.viewerPermissions.has("profile_fields.manage")

  const membership = useFragment<ProfileSettingsCustomFieldsFragment$key>(
    graphql`
      fragment ProfileSettingsCustomFieldsFragment on OrganizationMembership
      @argumentDefinitions(isPrivate: { type: "Boolean!" }) {
        organization {
          publicCustomFields: profileFields(
            status: visible
            isPrivate: $isPrivate
            isDefault: false
          ) {
            edges {
              node {
                id
                title
                webFormQuestion {
                  type
                }
              }
            }
          }
        }
        displayProfileValues: profileValues(isPrivate: $isPrivate) {
          edges {
            node {
              id
              profileFieldId
              value
            }
          }
        }
      }
    `,
    organizationMembershipKey
  )
  if (!membership) return null

  const profileValues = Relay.connectionToArray(membership.displayProfileValues)
  const profileFields = Relay.connectionToArray(
    membership.organization?.publicCustomFields
  )

  return (
    <DiscoSection padding={0} margin={0}>
      {profileFields.map((pf, i) => {
        const value = profileValues.find((pv) => pv.profileFieldId === pf.id)?.value
        if (!value && !isSelfViewing && !isAdmin) return null
        return (
          <Fragment key={pf.id}>
            <DiscoText
              testid={`ProfileSettingsCustomFields.${i}.title`}
              marginTop={2.5}
              variant={"body-sm-600"}
            >
              {pf.title}
            </DiscoText>
            <div
              className={classes.valueContainer}
              data-testid={`ProfileSettingsCustomFields.${i}.value`}
            >
              {renderValue(value, pf.webFormQuestion!.type)}
            </div>
          </Fragment>
        )
      })}
    </DiscoSection>
  )

  function renderValue(value: string | undefined, questionType: WebFormQuestionType) {
    if (!value) {
      return (
        <DiscoText variant={"body-sm"} color={"text.disabled"} fontStyle={"italic"}>
          {"No response received yet"}
        </DiscoText>
      )
    }
    if (questionType === "link") {
      return (
        <DiscoLink
          to={value}
          target={"_blank"}
          rel={"noreferrer noopener"}
          className={classes.textValue}
        >
          {truncateTextMiddle(value, 95).replace(/.*:\/\//, "")}
        </DiscoLink>
      )
    }
    return (
      <ReadMoreButton maxContentHeight={204} classes={{ button: classes.readMoreButton }}>
        <DiscoTextWithLinks classes={{ root: classes.textValue }}>
          {value}
        </DiscoTextWithLinks>
      </ReadMoreButton>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  valueContainer: {
    marginTop: theme.spacing(0.5),
  },
  textValue: {
    whiteSpace: "pre-line",
    overflowWrap: "break-word",
    ...theme.typography["body-sm"],
  },
  readMoreButton: {
    marginTop: 0,
    "& > *": {
      ...theme.typography["body-sm"],
    },
  },
}))

export const ProfileSettingsCustomFieldsSkeleton = () => (
  <DiscoSection padding={0} margin={0}>
    <DiscoTextSkeleton width={"30%"} />
    <DiscoTextSkeleton width={"60%"} />
    <DiscoTextSkeleton marginTop={2} width={"30%"} />
    <DiscoTextSkeleton width={"60%"} />
  </DiscoSection>
)

export default ProfileSettingsCustomFields
