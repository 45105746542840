import { UserNotesDeleteButtonMutation } from "@/user/notes/list/item/delete-button/__generated__/UserNotesDeleteButtonMutation.graphql"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { graphql, useMutation } from "react-relay"

interface UserNotesDeleteButtonProps {
  noteId: string
  children: OverridableDiscoButtonChildren
}

function UserNotesDeleteButton({ noteId, children }: UserNotesDeleteButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [commit, isDeleting] = useMutation<UserNotesDeleteButtonMutation>(graphql`
    mutation UserNotesDeleteButtonMutation($id: ID!) {
      response: deleteUserNote(id: $id) {
        deleteUserNoteId @deleteRecord
      }
    }
  `)

  return (
    <>
      <OverridableDiscoButton onClick={openModal}>{children}</OverridableDiscoButton>

      <DiscoWarningModal
        testid={"UserNotesDeleteButton.delete-warning-modal"}
        isOpen={isModalOpen}
        onClose={closeModal}
        title={"Are you sure you want to delete this note?"}
        description={"The note will be deleted permanently."}
        confirmationButtonProps={{
          onClick: deleteNote,
          shouldDisplaySpinner: isDeleting,
          children: "Yes, delete it",
        }}
        modalContentLabel={"Delete Note Dialog"}
      />
    </>
  )

  function deleteNote() {
    commit({
      variables: {
        id: noteId,
      },
      onCompleted() {
        displaySuccessToast({
          message: "Note deleted!",
          testid: "UserNotesDeleteButton.success-toast",
        })
        setIsModalOpen(false)
      },
      onError(error) {
        displayErrorToast(error)
      },
    })
  }

  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
  }
}

export default UserNotesDeleteButton
