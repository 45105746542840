import SlackUserProfileDropdown from "@/slack/slack-user-import-report/SlackUserProfileDropdown"
import { UnmappedSlackUserReportTableRowFragment$key } from "@/slack/slack-user-import-report/__generated__/UnmappedSlackUserReportTableRowFragment.graphql"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoTable } from "@disco-ui"
import { TableCell } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
interface UnmappedSlackUserReportTableRowProps extends TestIDProps {
  memberKey: UnmappedSlackUserReportTableRowFragment$key
}

function UnmappedSlackUserReportTableRow(props: UnmappedSlackUserReportTableRowProps) {
  const { memberKey } = props
  const classes = useStyles()

  const importMember = useFragment<UnmappedSlackUserReportTableRowFragment$key>(
    graphql`
      fragment UnmappedSlackUserReportTableRowFragment on SlackUserImportMember {
        organizationMembership {
          member {
            ...ProfileAvatarWithDetailsFragment
          }
          email
        }
        ...SlackUserProfileDropdownFragment
      }
    `,
    memberKey
  )

  if (!importMember.organizationMembership) return null

  return (
    <DiscoTable.Row>
      <TableCell>
        <ProfileAvatarWithDetails
          userKey={importMember.organizationMembership.member}
          details={importMember.organizationMembership.email}
        />
      </TableCell>
      <TableCell>
        <DiscoIcon icon={"sort"} width={24} height={24} className={classes.sortIcon} />
      </TableCell>
      <TableCell>
        <SlackUserProfileDropdown memberKey={importMember} />
      </TableCell>
    </DiscoTable.Row>
  )
}

const useStyles = makeUseStyles({
  sortIcon: {
    transform: "rotate(90deg)",
  },
})

export default UnmappedSlackUserReportTableRow
