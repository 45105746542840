import Badge from "@/admin/experiences/badges/Badge"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { SearchHitType } from "@/search/search-hits/SearchHit"
import SearchHitTemplate, {
  SearchHitTemplateSkeleton,
} from "@/search/search-hits/SearchHitTemplate"
import { PostSearchHitQuery } from "@/search/search-hits/kinds/__generated__/PostSearchHitQuery.graphql"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type PostHit = {
  id: string
  organization_id: string
  content: {
    name: string
    description: string | null
    richEditorContentDescription: string | null
  }
}

interface PostSearchHitProps extends TestIDProps {
  hit: SearchHitType
}

function PostSearchHit({ hit }: PostSearchHitProps) {
  const classes = useStyles()
  const copyToClipboard = useCopyToClipboard()
  const history = useHistory()

  const { post } = useLazyLoadQuery<PostSearchHitQuery>(
    graphql`
      query PostSearchHitQuery($id: ID!) {
        post: node(id: $id) {
          ... on Post {
            id
            content {
              id
              name
              createdBy {
                id
                fullName
                ...ProfileAvatarWithDetailsFragment
                ...ProfileAvatarFragment
              }
            }
            product {
              name
              slug
            }
            feed {
              id
              name
              app {
                badge {
                  ...BadgeFragment
                }
              }
            }
          }
        }
      }
    `,
    {
      id: hit.id as GlobalID,
    }
  )

  const content = post?.content
  const feed = post?.feed
  const user = content?.createdBy
  const product = post?.product

  if (!post) return null
  if (!content) return null

  return (
    <SearchHitTemplate
      hit={hit}
      icon={renderIcon()}
      name={content.name}
      details={renderDetails()}
      onClick={handleClick}
      onCopy={handleCopyLink}
    />
  )

  function renderIcon() {
    if (!feed) return null

    return (
      <div className={classes.icon}>
        <Badge badgeKey={feed.app.badge} size={40} className={classes.badge} />

        {user && (
          <div className={classes.subIconContainer}>
            <ProfileAvatar userKey={user} />
          </div>
        )}
      </div>
    )
  }

  function renderDetails() {
    return (
      <DiscoText
        variant={"body-sm"}
        color={"groovy.neutral.400"}
        truncateText={1}
        className={classes.subtitle}
        display={"inline"}
      >
        {"Post"}
        {user?.fullName && (
          <>
            {" • "}
            {user.fullName}
          </>
        )}
        {feed?.name && (
          <>
            {" • "}
            <DiscoText
              component={"span"}
              variant={"body-sm"}
              color={"primary.main"}
              truncateText={1}
              className={classes.feed}
            >
              {feed.name}
            </DiscoText>
          </>
        )}
        {product?.name && (
          <>
            {" • "}
            <DiscoText
              component={"span"}
              variant={"body-sm"}
              truncateText={1}
              className={classes.feed}
              color={"groovy.neutral.400"}
            >
              {product.name}
            </DiscoText>
          </>
        )}
      </DiscoText>
    )
  }

  function getLink() {
    if (post?.product) {
      return {
        pathname: generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
          productSlug: post.product.slug,
          feedId: post.feed!.id,
        }),
        search: setSearchParams<GlobalDrawerParams<"post">>(location.search, {
          postId: post.id!,
        }),
      }
    }

    return {
      pathname: generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, {
        feedId: post!.feed!.id,
      }),
      search: setSearchParams<GlobalDrawerParams<"post">>(location.search, {
        postId: post!.id!,
      }),
    }
  }

  function handleClick() {
    const { pathname, search } = getLink()
    history.push({ pathname, search })
  }

  function handleCopyLink(e: React.MouseEvent<HTMLButtonElement>) {
    if (!post?.id) return
    e.stopPropagation()
    const { pathname } = getLink()
    const postLink = `${window.location.origin}${pathname}?postId=${post.id}`
    copyToClipboard(postLink)
  }
}

const useStyles = makeUseStyles((theme) => ({
  subtitle: {
    width: "100%",
  },
  feed: {
    display: "contents",
  },
  icon: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  badge: {
    "& svg": {
      width: 32,
      height: 32,
    },
  },
  subIconContainer: {
    position: "absolute",
    bottom: -3,
    right: -3,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.medium,
  },
}))

export const PostSearchHitSkeleton: React.FC<PostSearchHitProps> = () => {
  return <SearchHitTemplateSkeleton />
}

export default Relay.withSkeleton<PostSearchHitProps>({
  component: PostSearchHit,
  skeleton: PostSearchHitSkeleton,
})
