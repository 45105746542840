import { useAuthUser } from "@/core/context/AuthUserContext"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import { UserNotesItemFragment$key } from "@/user/notes/list/item/__generated__/UserNotesItemFragment.graphql"
import UserNotesItemMoreActionsDropdown from "@/user/notes/list/item/more-actions-dropdown/UserNotesItemMoreActionsDropdown"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { useTheme } from "@material-ui/core"
import { getTimeDifferenceAsText } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { utcToZonedTime } from "date-fns-tz"
import { graphql, useFragment } from "react-relay"

interface UserNotesItemProps extends TestIDProps {
  noteItemKey: UserNotesItemFragment$key
}

function UserNotesItem(props: UserNotesItemProps) {
  const { noteItemKey, testid } = props
  const timeZone = useUserTimezone()
  const theme = useTheme()
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()
  const { authUser } = useAuthUser({ required: true })

  const note = useFragment<UserNotesItemFragment$key>(
    graphql`
      fragment UserNotesItemFragment on UserNote {
        id
        body
        createdAt
        addedByUser {
          id
          ...ProfileAvatarWithDetailsFragment
        }
      }
    `,
    noteItemKey
  )

  if (!note || !note.addedByUser) {
    return null
  }

  const { addedByUser, createdAt } = note

  return (
    <div className={classNames(classes.noteWrapper, showOnHoverClasses.hoverable)}>
      {/* More Actions Dropdown only visible for notes made by the current user */}
      {authUser.id === addedByUser.id && (
        <UserNotesItemMoreActionsDropdown
          moreActionsButtonClassName={classNames(
            classes.noteMoreActionsButton,
            showOnHoverClasses.showable
          )}
          testid={testid}
          noteId={note.id}
        />
      )}

      <ProfileAvatarWithDetails
        userKey={addedByUser}
        testid={`${testid}.UserNotesItem.addedByUser`}
        details={getTimeDifferenceAsText(utcToZonedTime(new Date(createdAt), timeZone))}
      />
      <DiscoEditor
        testid={`${testid}.rich-editor`}
        className={classes.noteText}
        defaultValue={note.body}
        textColor={theme.palette.text.secondary}
        backgroundColor={"transparent"}
        readOnly
        disableGutter
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  noteWrapper: {
    marginTop: theme.spacing(2),
    position: "relative",
    padding: theme.spacing(0, 1),
  },
  noteMoreActionsButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: "0",
  },
  noteText: {
    marginLeft: theme.spacing(6.5),
    marginTop: theme.spacing(1),
  },
}))

export default UserNotesItem
