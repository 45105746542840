import { GlobalID } from "@/relay/RelayTypes"
import ProfilePopover from "@/user/common/profile-popover/ProfilePopover"
import { DiscoButtonProps } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useState } from "react"

type Props = DiscoButtonProps & {
  children: OverridableDiscoButtonChildren
  userId: GlobalID
  stopPropagation?: boolean
  testid?: string
  customAnchor?: HTMLButtonElement | HTMLDivElement | null
}

function OpenProfilePopoverButton(props: Props) {
  const {
    children,
    userId,
    testid,
    customAnchor,
    stopPropagation = false,
    ...rest
  } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLDivElement | null>(
    null
  )

  return (
    <>
      <OverridableDiscoButton
        testid={testid && `${testid}.ProfilePopover.open-button`}
        onClick={handleClick}
        color={"transparent"}
        {...rest}
      >
        {children}
      </OverridableDiscoButton>
      <ProfilePopover userId={userId} anchorEl={anchorEl} onClose={handleClose} />
    </>
  )

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (stopPropagation) {
      event.stopPropagation()
    }
    if (customAnchor) {
      setAnchorEl(customAnchor)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  function handleClose(event?: React.MouseEvent<HTMLButtonElement> | undefined) {
    if (stopPropagation && event) {
      event.stopPropagation()
    }
    setAnchorEl(null)
  }
}

export default OpenProfilePopoverButton
