import { appTheme } from "@assets/style/appMuiTheme"
import { DiscoThemeProvider } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { render, unmountComponentAtNode } from "react-dom"

const userConfirmation = (message: string, callback: (ok: boolean) => void) => {
  const container = document.createElement("div")
  container.setAttribute("custom-confirmation-navigation", "")
  document.body.appendChild(container)

  const closeModal = (callbackState: boolean) => {
    unmountComponentAtNode(container)
    document.body.removeChild(container)
    callback(callbackState)
  }

  render(
    <DiscoThemeProvider theme={appTheme}>
      <DiscoWarningModal
        title={"Heading away? Be sure to save your edits!"}
        description={
          "It appears you have unsaved changes. Be sure to save your work before navigating away!"
        }
        variant={"primary"}
        testid={"unsavedChangesRouterModal.DiscoWarningModal"}
        isOpen={true}
        icon={"save"}
        modalContentLabel={"unsaved changes"}
        onCancel={() => closeModal(true)}
        confirmationButtonProps={{
          onClick: () => closeModal(false),
          children: "Continue Editing",
        }}
        cancelButtonText={"Exit Without Saving"}
      />
    </DiscoThemeProvider>,

    container
  )
}

export default userConfirmation
