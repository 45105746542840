/**
 * @generated SignedSource<<d8ee67a5f5b16f97549bfac7e046ba17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NotificationChannel = "email" | "slack" | "in_app" | "mobile" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationFamilyListItemFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly notificationKinds: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly channels: ReadonlyArray<NotificationChannel>;
        readonly " $fragmentSpreads": FragmentRefs<"NotificationKindListItemFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "NotificationFamilyListItemFragment";
};
export type NotificationFamilyListItemFragment$key = {
  readonly " $data"?: NotificationFamilyListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationFamilyListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationFamilyListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationKindNodeConnection",
      "kind": "LinkedField",
      "name": "notificationKinds",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationKindNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NotificationKind",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "channels",
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "organizationId",
                      "variableName": "organizationId"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "NotificationKindListItemFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NotificationFamily",
  "abstractKey": null
};
})();

(node as any).hash = "a878f9a37c06a8e90a3e82b07afb6ccd";

export default node;
