import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ChevronIcon from "@/core/ui/iconsax/linear/arrow-up-1.svg"
import ExternalIcon from "@/core/ui/iconsax/linear/export-2.svg"
import OccurrenceWithDetails from "@/occurrence/common/OccurrenceWithDetails"
import { ProfileSettingsEventRegistrationTableRowFragment$key } from "@/user/settings/subtabs/registrations/table/__generated__/ProfileSettingsEventRegistrationTableRowFragment.graphql"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIconButton, DiscoTable, DiscoText, DiscoTextButton } from "@disco-ui"
import { Collapse, TableCell } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { isFuture } from "date-fns"
import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  occurrenceRSVPKey: ProfileSettingsEventRegistrationTableRowFragment$key
}

function ProfileSettingsEventRegistrationTableRow(props: Props) {
  const { occurrenceRSVPKey, testid = "ProfileSettingsEventRegistrationTableRow" } = props

  const drawer = useGlobalDrawer("event")
  const isMobile = useIsMobile()
  const timezone = useUserTimezone()
  const [isExpanded, setIsExpanded] = useState(false)
  const classes = useStyles({
    isExpanded,
  })

  const { occurrence } =
    useFragment<ProfileSettingsEventRegistrationTableRowFragment$key>(
      graphql`
        fragment ProfileSettingsEventRegistrationTableRowFragment on OccurrenceRSVP {
          occurrence {
            id
            name
            datetimeRange
            didViewerAttend
            ...OccurrenceWithDetailsFragment
          }
        }
      `,
      occurrenceRSVPKey
    )

  const isFutureEvent = isFuture(new Date(occurrence.datetimeRange[0]))
  const attendedText = isFutureEvent ? "-" : occurrence.didViewerAttend ? "Yes" : "No"

  return (
    <DiscoTable.Row
      onClick={isMobile ? undefined : openEventDrawer}
      testid={`${testid}.event.${occurrence.name}`}
    >
      {isMobile ? (
        <TableCell width={"100%"} onClick={toggleCollapse}>
          <div className={classes.header}>
            <OccurrenceWithDetails occurrenceKey={occurrence} hideDates />
            <DiscoIconButton>
              <ChevronIcon className={classes.chevronIcon} />
            </DiscoIconButton>
          </div>
          <Collapse in={isExpanded} className={classes.collapseContainer}>
            <DiscoText
              variant={"body-xs-500"}
              display={"block"}
              marginTop={1.5}
              color={"groovy.grey.300"}
            >
              {"Date & Time"}
            </DiscoText>
            <DiscoText variant={"body-sm"} marginTop={0.5} color={"text.secondary"}>
              {formatDateWithOptions({
                format:
                  DATE_FORMAT.DEFAULT_DATE_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT,
                timeZone: timezone,
              })(new Date(occurrence.datetimeRange[0]))}
            </DiscoText>
            <DiscoText
              variant={"body-xs-500"}
              display={"block"}
              marginTop={1.5}
              color={"groovy.grey.300"}
            >
              {"Attended"}
            </DiscoText>
            <DiscoText
              variant={"body-sm"}
              marginTop={0.5}
              color={"text.secondary"}
              testid={`${testid}.attended`}
            >
              {attendedText}
            </DiscoText>
            <DiscoTextButton
              leftIcon={<ExternalIcon />}
              onClick={openEventDrawer}
              className={classes.mobileLinkButton}
              textVariant={"body-sm-600"}
            >
              {"View Event"}
            </DiscoTextButton>
          </Collapse>
        </TableCell>
      ) : (
        <>
          <TableCell width={"65%"}>
            <OccurrenceWithDetails occurrenceKey={occurrence} />
          </TableCell>
          <TableCell width={"35%"}>
            <DiscoText
              variant={"body-sm"}
              marginTop={0.5}
              color={"text.secondary"}
              testid={`${testid}.attended`}
            >
              {attendedText}
            </DiscoText>
          </TableCell>
        </>
      )}
    </DiscoTable.Row>
  )

  function toggleCollapse() {
    setIsExpanded((v) => !v)
  }

  function openEventDrawer() {
    drawer.open({
      drawerOccurrenceId: occurrence.id,
      drawerEventTab: "details",
    })
  }
}

type StyleProps = {
  isExpanded: boolean
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mobileLinkButton: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
  chevronIcon: ({ isExpanded }: StyleProps) => ({
    transition: "transform 300ms ease-in-out",
    transform: `rotate(${isExpanded ? 180 : 90}deg)`,
  }),
  collapseContainer: {
    marginLeft: theme.spacing(6.5),
  },
}))

export default ProfileSettingsEventRegistrationTableRow
