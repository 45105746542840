import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import UserAvatar, { UserAvatarShape } from "@/user/common/avatar/UserAvatar"
import OpenProfilePopoverButton from "@/user/common/profile-popover/OpenProfilePopoverButton"
import { getUserFullName } from "@/user/util/userUtils"
import { DiscoText } from "@disco-ui"
import classNames from "classnames"

interface ProfileListDrawerListItemProps {
  user: UserAvatarShape
  testid?: string
}

function ProfileListDrawerListItem({ user, testid }: ProfileListDrawerListItemProps) {
  const classes = useStyles()

  const avatarAndName = (
    <>
      {/* Avatar */}
      <UserAvatar
        testid={`${testid}.MultipleAvatars.${user.first_name}`}
        user={user}
        size={40}
      />

      {/* Name */}
      <DiscoText
        variant={"body-sm"}
        testid={`${testid}.name`}
        color={"text.secondary"}
        className={classNames(classes.text, "fs-mask")}
      >
        {user?.name ?? getUserFullName(user)}
      </DiscoText>
    </>
  )

  return (
    <>
      {user.id ? (
        <div className={classes.container} data-testid={`${testid}.root`}>
          <OpenProfilePopoverButton
            className={classes.baseButton}
            testid={`${user.first_name}${user.last_name}.ProfilePopover`}
            userId={user.id}
          >
            <div className={classes.container}>{avatarAndName}</div>
          </OpenProfilePopoverButton>
        </div>
      ) : (
        <div className={classes.container} data-testid={`${testid}.root`}>
          {avatarAndName}
        </div>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  baseButton: {
    padding: "0px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1.5),
    gap: theme.spacing(1.5),
  },
  text: {
    fontWeight: 500,
  },
}))

export default ProfileListDrawerListItem
