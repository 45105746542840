import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveSlackUserImportContext } from "@/core/context/ActiveSlackUserImportContext"
import Relay from "@/relay/relayUtils"
import UnmappedSlackUserReportTableRow from "@/slack/slack-user-import-report/UnmappedSlackUserReportTableRow"
import {
  SlackUserImportMemberUnmappedStatus,
  UnmappedSlackUserReportTablePaginationQuery,
} from "@/slack/slack-user-import-report/__generated__/UnmappedSlackUserReportTablePaginationQuery.graphql"
import { UnmappedSlackUserReportTableQuery } from "@/slack/slack-user-import-report/__generated__/UnmappedSlackUserReportTableQuery.graphql"
import { UnmappedSlackUserReportTable_PaginationFragment$key } from "@/slack/slack-user-import-report/__generated__/UnmappedSlackUserReportTable_PaginationFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoTable,
  DiscoTableSkeletonWithHeader,
  DiscoText,
  SelectOption,
} from "@disco-ui"
import DiscoTableFilterDropdown from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import DiscoTableSearchInput, {
  DiscoTableSearchInputSkeleton,
} from "@disco-ui/table/header/search/DiscoTableSearchInput"
import {
  DiscoTableSortDropdownSkeleton,
  DiscoTableToolbarStateWithSort,
} from "@disco-ui/table/header/search/DiscoTableSortDropdown"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useRef, useState } from "react"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"

const IMPORT_MEMBERS_PER_PAGE = 5

const TYPE_OPTIONS: UnmappedSlackUserReportTableTypeOption[] = [
  {
    value: "all",
    title: "All",
  },
  {
    value: "unmatched",
    title: "Unmatched",
  },
  {
    value: "pending",
    title: "Pending",
  },
]

type UnmappedSlackUserReportTableTypeOption =
  SelectOption<SlackUserImportMemberUnmappedStatus>

type UnmappedSlackUserReportTableToolbarState = DiscoTableToolbarStateWithSort & {
  status: UnmappedSlackUserReportTableTypeOption | null
}

function UnmappedSlackUserReportTable() {
  const { pendingMatches } = useActiveSlackUserImportContext()

  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const [activePage, setActivePage] = useState(1)
  const [toolbarState, setToolbarState] =
    useState<UnmappedSlackUserReportTableToolbarState>({
      search: "",
      status: {
        value: "all",
        title: "All",
      },
      sort: {
        id: "none",
        order: { field: "created_on" },
        title: "Sort",
      },
      direction: "ASC",
    })

  const { organization } = useLazyLoadQuery<UnmappedSlackUserReportTableQuery>(
    graphql`
      query UnmappedSlackUserReportTableQuery(
        $id: ID!
        $first: Int
        $after: String
        $last: Int
        $before: String
        $status: SlackUserImportMemberUnmappedStatus
        $search: String
      ) {
        organization: node(id: $id) {
          ... on Organization {
            slackUserImport {
              ...UnmappedSlackUserReportTable_PaginationFragment
                @arguments(
                  first: $first
                  after: $after
                  last: $last
                  before: $before
                  status: $status
                  search: $search
                )
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      first: IMPORT_MEMBERS_PER_PAGE,
      status: toolbarState.status?.value || null,
      search: toolbarState.search,
    },
    { fetchPolicy: "network-only" }
  )

  const { data, refetch } = usePaginationFragment<
    UnmappedSlackUserReportTablePaginationQuery,
    UnmappedSlackUserReportTable_PaginationFragment$key
  >(
    graphql`
      fragment UnmappedSlackUserReportTable_PaginationFragment on SlackUserImport
      @refetchable(queryName: "UnmappedSlackUserReportTablePaginationQuery")
      @argumentDefinitions(
        first: { type: "Int" }
        after: { type: "String" }
        last: { type: "Int" }
        before: { type: "String" }
        status: { type: "SlackUserImportMemberUnmappedStatus" }
        search: { type: "String" }
      ) {
        members(
          unmapped: true
          first: $first
          after: $after
          last: $last
          before: $before
          status: $status
          search: $search
        ) @connection(key: "UnmappedSlackUserReportTable_members") {
          __id
          totalCount
          edges {
            cursor
            node {
              id
              ...UnmappedSlackUserReportTableRowFragment
            }
          }
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization?.slackUserImport || null
  )
  // save the inital totalCount, before any filters are applied
  const totalCount = useRef(data?.members.totalCount)

  if (!data) return null

  const importMembers = Relay.connectionToArray(data?.members)
  return (
    <>
      <div className={classes.tagContainer}>
        <DiscoText variant={"body-md-600"}>{"Mapped Members"}</DiscoText>
        {totalCount.current && (
          <DiscoTag name={`${pendingMatches}/${totalCount.current}`} />
        )}
      </div>
      <DiscoTable
        activePage={activePage}
        setActivePage={setActivePage}
        header={[
          { value: "Disco User", width: "47.5%" },
          { value: "", key: "link", width: "5%" },
          { value: "Slack Profile", width: "47.5%" },
        ]}
        onPaginate={refetch}
        rows={importMembers.map((member) => {
          return <UnmappedSlackUserReportTableRow key={member.id} memberKey={member} />
        })}
        connection={{
          cursorsList: data.members.edges.map((e) => e.cursor),
          totalCount: data.members.totalCount || 0,
          pageInfo: {
            endCursor: data.members.pageInfo.endCursor,
            startCursor: data.members.pageInfo.startCursor,
          },
        }}
        rowsPerPage={IMPORT_MEMBERS_PER_PAGE}
        headerButtons={
          <>
            <div className={classes.lhs}>
              <DiscoTableSearchInput
                testid={"AdminGroupsListPage.search"}
                onChange={handleSearchChange}
              />
            </div>
            <div className={classes.rhs}>
              <DiscoTableFilterDropdown
                testid={"ProductApplicationTable.status-select"}
                options={TYPE_OPTIONS}
                onOptionSelect={handleFilterTypeChange}
                selectedOption={toolbarState.status}
              />
            </div>
          </>
        }
      />
    </>
  )

  function handleSearchChange(value: string) {
    setToolbarState({
      ...toolbarState,
      search: value,
    })
  }

  function handleFilterTypeChange(opt: UnmappedSlackUserReportTableTypeOption | null) {
    setToolbarState({
      ...toolbarState,
      status: opt?.value ? opt : null,
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  lhs: {
    minWidth: "240px",
    width: "100%",
    paddingRight: theme.spacing(1.5),
  },
  rhs: {
    display: "flex",
    gap: theme.spacing(1.5),
  },
  headerButtons: {
    display: "flex",
    gap: theme.spacing(1),
    width: "100%",
  },
  tagContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(1),
    margin: theme.spacing(2.5, 2.5, 0),
  },
}))

export function UnmappedSlackUserReportTableSkeleton() {
  const classes = useStyles()

  return (
    <DiscoTableSkeletonWithHeader
      headerButtons={
        <div className={classes.headerButtons}>
          <div className={classes.lhs}>
            <DiscoTableSearchInputSkeleton />
          </div>
          <div className={classes.rhs}>
            <DiscoTableSortDropdownSkeleton />
          </div>
        </div>
      }
      header={[
        { value: "Disco User" },
        { value: "", key: "link" },
        { value: "Slack Profile" },
      ]}
      rows={IMPORT_MEMBERS_PER_PAGE}
      rowHeight={60}
    />
  )
}

export default Relay.withSkeleton({
  component: UnmappedSlackUserReportTable,
  skeleton: UnmappedSlackUserReportTableSkeleton,
})
