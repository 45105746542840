import { DiscoChip, DiscoTooltip } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
interface ProfileSettingsPrivacyChipProps extends TestIDProps {
  isPrivate: boolean
}

function ProfileSettingsPrivacyChip({
  isPrivate,
  testid = "ProfileSettingsPrivacyChip",
}: ProfileSettingsPrivacyChipProps) {
  return (
    <DiscoTooltip
      content={
        isPrivate
          ? "This field is only visible to community admins and won't show up on the public profile"
          : "This field is visible to everyone in the community and will show up on the public profile"
      }
    >
      <DiscoChip
        label={isPrivate ? "Visible to Admin Only" : "Visible to Everyone"}
        color={isPrivate ? "yellow" : "blue"}
        testid={testid}
      />
    </DiscoTooltip>
  )
}

export default ProfileSettingsPrivacyChip
