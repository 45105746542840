/**
 * @generated SignedSource<<f46ebdb0856230dd24f137f22a824deb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SlackUserProfileDropdownFragment$data = {
  readonly id: string;
  readonly mappedSlackUser: {
    readonly " $fragmentSpreads": FragmentRefs<"SlackUserAvatarWithEmailFragment">;
  } | null;
  readonly isFuzzyMatch: boolean;
  readonly " $fragmentType": "SlackUserProfileDropdownFragment";
};
export type SlackUserProfileDropdownFragment$key = {
  readonly " $data"?: SlackUserProfileDropdownFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SlackUserProfileDropdownFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SlackUserProfileDropdownFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SlackUserImportMember",
      "kind": "LinkedField",
      "name": "mappedSlackUser",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SlackUserAvatarWithEmailFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFuzzyMatch",
      "storageKey": null
    }
  ],
  "type": "SlackUserImportMember",
  "abstractKey": null
};

(node as any).hash = "0c9066fbad0fc06f2b5e72633997f680";

export default node;
