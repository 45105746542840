import FormStore from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import { ProfileSettingsProfileFormFieldsFragment$key } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsProfileFormFieldsFragment.graphql"
import { UpdateUserProfileInput } from "@/user/settings/subtabs/profile/__generated__/ProfileSettingsProfileTabMutation.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import TimezoneDropdown, {
  TimezoneDropdownOption,
} from "@components/dropdown/timezone/TimezoneDropdown"
import { DiscoFormControl, DiscoInput, DiscoSection } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  form: FormStore<UpdateUserProfileInput>
  organizationKey: ProfileSettingsProfileFormFieldsFragment$key
}

function ProfileSettingsProfileFormFields(props: Props) {
  const { form, testid = "ProfileSettingsProfileFormFields", organizationKey } = props
  const classes = useStyles()
  const isMobile = useIsMobile()

  const organization = useFragment<ProfileSettingsProfileFormFieldsFragment$key>(
    graphql`
      fragment ProfileSettingsProfileFormFieldsFragment on Organization {
        defaultFields: profileFields(status: visible, isDefault: true) {
          edges {
            node {
              id
              title
              isPrivate
            }
          }
        }
      }
    `,
    organizationKey
  )
  const profileFields = Relay.connectionToArray(organization.defaultFields)

  const showBio = profileFields.some((pf) => pf.title === "Short Bio")

  const selectedTimezoneSubtitle = formatDateWithOptions({
    timeZone: form.state.timezone!,
    format: DATE_FORMAT.UTC_OFFSET,
  })(new Date())

  return (
    <DiscoSection
      margin={0}
      marginTop={isMobile ? 1.5 : 2.5}
      padding={2}
      className={classes.root}
      testid={"ProfileSettingsProfileFormFields"}
      groovyDepths={"insideCard"}
    >
      <DiscoFormControl label={"First Name"}>
        <DiscoInput
          inputProps={{ "data-testid": `${testid}.first-name-input` }}
          value={form.state.firstName}
          onChange={(e) => (form.state.firstName = e.target.value)}
        />
      </DiscoFormControl>
      <DiscoFormControl label={"Last Name"}>
        <DiscoInput
          inputProps={{ "data-testid": `${testid}.last-name-input` }}
          value={form.state.lastName}
          onChange={(e) => (form.state.lastName = e.target.value)}
        />
      </DiscoFormControl>
      {showBio && (
        <DiscoFormControl label={"Headline/Bio"} className={classes.bioField}>
          <DiscoInput
            inputProps={{ "data-testid": `${testid}.bio-input` }}
            value={form.state.bio}
            onChange={(e) => (form.state.bio = e.target.value)}
            multiline
            placeholder={
              "What is your profession? Where do you work? What are you hoping to achieve in your career? etc."
            }
            minHeight={"120px"}
          />
        </DiscoFormControl>
      )}
      {
        <DiscoFormControl
          label={"Local time"}
          error={Boolean(form.errorsByField.timezone)}
          errorMessages={form.errorsByField.timezone}
          style={{ gridColumn: "span 2" }}
        >
          <TimezoneDropdown
            testid={`${testid}.TimezoneDropdown`}
            selectedOption={{
              value: form.state.timezone!,
              title: form.state.timezone!,
              context: {
                subtitle: selectedTimezoneSubtitle,
              },
            }}
            onSelect={handleTimezoneChange}
          />
        </DiscoFormControl>
      }
    </DiscoSection>
  )

  function handleTimezoneChange(t: TimezoneDropdownOption | null) {
    if (!t) return
    form.state.timezone = t.value
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: theme.spacing(2.5),

    [theme.breakpoints.down("xs")]: {
      "& > *": {
        gridColumn: "span 2",
      },
    },
  },
  bioField: {
    gridColumn: "span 2",
  },
}))

export default observer(ProfileSettingsProfileFormFields)
