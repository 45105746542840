/**
 * @generated SignedSource<<9f082e3f7129c751fc77d2d92acfd00d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileSettingsUserSectionFragment$data = {
  readonly id: string;
  readonly bio: string;
  readonly websiteUrl: string | null;
  readonly timezone: string;
  readonly organizationMembership: {
    readonly id: string;
    readonly organization: {
      readonly defaultFields: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly title: string;
            readonly isPrivate: boolean;
          };
        }>;
      };
    };
  } | null;
  readonly " $fragmentType": "ProfileSettingsUserSectionFragment";
};
export type ProfileSettingsUserSectionFragment$key = {
  readonly " $data"?: ProfileSettingsUserSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileSettingsUserSectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileSettingsUserSectionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "websiteUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "concreteType": "OrganizationMembership",
      "kind": "LinkedField",
      "name": "organizationMembership",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": "defaultFields",
              "args": [
                {
                  "kind": "Literal",
                  "name": "isDefault",
                  "value": true
                },
                {
                  "kind": "Literal",
                  "name": "status",
                  "value": "visible"
                }
              ],
              "concreteType": "ProfileFieldNodeConnection",
              "kind": "LinkedField",
              "name": "profileFields",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProfileFieldNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProfileField",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isPrivate",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "profileFields(isDefault:true,status:\"visible\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "87e54767f2ace706deb7a45967af989f";

export default node;
