export const SearchTypes = [
  "post",
  "user",
  "product",
  "chatChannel",
  "feed",
  "chatMessage",
  "contentUsage",
  "occurrence",
] as const
export type SearchType = (typeof SearchTypes)[number]

export const SearchTypeLabels: Record<SearchType, string> = {
  post: "Posts",
  user: "People",
  product: "Products",
  chatChannel: "Channels",
  feed: "Feeds",
  contentUsage: "Content",
  occurrence: "Events",
  chatMessage: "Messages",
}
